const moment = require('moment');
const parseString = require('xml2js').parseString;
const slugify = require('slugify');

const Variables = function () {};

Variables.str_shuffle = (str) => {
  var newStr = [];
  if (arguments.length < 1) {
    throw 'str_shuffle : Parameter str not specified';
  }

  if (typeof str !== 'string') {
    throw 'str_shuffle : Parameter str ( = ' + str + ') is not a string';
  }

  str = str.split('');
  while (str.length) {
    newStr.push(str.splice(Math.floor(Math.random() * (str.length - 1)), 1)[0]);
  }

  return newStr.join('');
};

Variables.registerNumber = (prefix = 'INT') => {
  const str = 'ABCDEFGHJKLMNOPUQRSTUVWXYZ1234567890';
  const newPass = prefix + moment().format('YYMMDDHHmmss') + Variables.str_shuffle(str).substr(0, 2);
  return newPass;
};

Variables.registerNumber2 = (prefix = 'INT') => {
  const str = 'ABCDEFGHJKLMNOPUQRSTUVWXYZ1234567890';
  const newPass = prefix + '-' + Variables.str_shuffle(str).substr(0, 5);
  return newPass;
};

Variables.registerTicketNumber = (prefix = 'INT') => {
  let newStr = [];
  const str = 'ABCDEFGHJKLMNOPUQRSTUVWXYZ1234567890';
  const parseStr = str.split('');
  while (parseStr.length) {
    newStr.push(parseStr.splice(Math.floor(Math.random() * (parseStr.length - 1)), 1)[0]);
  }

  const newPass = prefix + '-' + newStr.join('').substr(0, 2) + '-' + newStr.join('').substr(0, 4);
  return newPass;
};

Variables.lastName = (name) => {
  const str = name.split(' ');
  let result = '';
  str.forEach((el) => {
    result += slugify(el.substr(0, 2), { lower: true });
  });
  return result;
};

Variables.parse = async (file) => {
  const promise = await new Promise((resolve, reject) => {
    parseString(file, (error, result) => {
      if (error) reject(error);
      else resolve(result);
    });
  });
  return promise;
};

Variables.createPassword = () => {
  const str = 'abcdefghjklmnoprstuvwxyz';
  const int = '1234567890';
  const newPass = Variables.str_shuffle(int).substr(0, 4) + Variables.str_shuffle(str).substr(0, 2);
  return newPass;
};

Variables.createSmsPassword = () => {
  return Math.floor(Math.random() * 999999);
};

Variables.parse = async (file) => {
  const promise = await new Promise((resolve, reject) => {
    parseString(file, (error, result) => {
      if (error) reject(error);
      else resolve(result);
    });
  });
  return promise;
};

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

Variables.niceBytes = (x) => {
  let l = 0,
    n = parseInt(x, 10) || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

Variables.bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  let k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  if (sizes[i] == 'Bytes') {
    return (bytes / Math.pow(k, i) / 1024).toFixed(dm) + ' ' + sizes[i];
  } else {
    return (bytes / Math.pow(k, i)).toFixed(dm) + ' ' + sizes[i];
  }
};

Variables.bytesToSizePrint = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  if (sizes[i] == 'Bytes') {
    return (bytes / Math.pow(k, i) / 1024).toFixed(2);
  } else {
    return (bytes / Math.pow(k, i)).toFixed(2);
  }
};

function replaceStr(str, find, replace) {
  for (var i = 0; i < find.length; i++) {
    str = str.replace(new RegExp(find[i], 'gi'), replace[i]);
  }
  return str;
}

module.exports = Variables;
