<template>
  <div class="transfer-wrap">
    <div class="transfer-left">
      <b-img :src="product.gorseller | transferImage(product.k_no)" width="100" />
    </div>
    <div class="transfer-right">
      <div class="transfer-right__header">
        <div class="content-title">{{ product.icerik[defaultDil].baslik }}</div>
        <div class="special">
          <span v-b-tooltip.hover.top="'Araç Kapasitesi'">
            <i class="fad fa-users-class"></i> {{ product.kapasite.min }} / {{ product.kapasite.max }}
          </span>
          <span v-b-tooltip.hover.top="'Bagaj Kapasitesi'"><i class="fad fa-suitcase"></i> {{ product.bagaj }}</span>
          <span v-b-tooltip.hover.top="'Deneyimli Sürücü'"><i class="fad fa-car-bus"></i></span>
        </div>
      </div>
      <div class="transfer-right__body">
        <div class="body-wrap">
          <div class="transfer-tarih text-danger"><span>Alış Tarihi : </span> {{ transfer.a_tarih }}</div>
          <div class="transfer-content">
            <div class="body-nereden">
              <span>{{ transfer.nereden[defaultDil] }}</span>
            </div>
            <div class="ortalama-sure">
              <div class="ortalam-sure-text">
                <span><i class="fad fa-clock"></i> {{ transfer.sure }}</span>
                <span>Ortalama Süre</span>
              </div>
            </div>
            <div class="body-nereye">
              <span>{{ transfer.nereye[defaultDil] }}</span>
            </div>
          </div>
        </div>
        <div class="body-wrap" v-if="transfer.tipi == 'gelis_gidis' && transfer.d_tarih != null">
          <div class="transfer-tarih text-danger"><span>Gidiş Tarihi : </span> {{ transfer.d_tarih }}</div>
          <div class="transfer-content">
            <div class="body-nereden">
              <span>{{ transfer.nereye[defaultDil] }}</span>
            </div>
            <div class="ortalama-sure">
              <div class="ortalam-sure-text">
                <span><i class="fad fa-clock"></i> {{ transfer.sure }}</span>
                <span>Ortalama Süre</span>
              </div>
            </div>
            <div class="body-nereye">
              <span>{{ transfer.nereden[defaultDil] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="transfer-right__footer">
        <div class="trans-price" v-if="transfer.tipi == 'gelis_gidis' && transfer.d_tarih != null">
          {{ (product.fiyat * 2) | exc(transfer.kur_turu) }} {{ transfer.kur_turu }}
        </div>
        <div class="trans-price" v-else>{{ product.fiyat | exc(transfer.kur_turu) }} {{ exhange.banka_kodu }}</div>
        <div class="trans-selector">
          <b-button squared size="sm" variant="warning" @click="handlerRezervasyonYap(product, false)">
            <i class="fad fa-cart-plus"></i> Sepete Ekle
          </b-button>
          <b-button squared size="sm" variant="success" @click="handlerRezervasyonYap(product, true)">
            <i class="fad fa-bell-on"></i> Rezervasyon Yap
          </b-button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="trans-wrap">
    <div class="trans trans-list">
      <div class="trans-header">
        <figure class="trans-banner">
          <b-img :src="product.gorseller | transferImage(product.k_no)" width="100" />
        </figure>
      </div>
      <div class="trans-content">
        <div class="trans-content-title">
          <div class="content-title">{{ product.icerik[defaultDil].baslik }}</div>
          <div class="special">
            <span v-b-tooltip.hover.top="'Araç Kapasitesi'">
              <i class="fad fa-users-class"></i> {{ product.kapasite.min }} / {{ product.kapasite.max }}
            </span>
            <span v-b-tooltip.hover.top="'Bagaj Kapasitesi'"><i class="fad fa-suitcase"></i> {{ product.bagaj }}</span>
            <span v-b-tooltip.hover.top="'Deneyimli Sürücü'"><i class="fad fa-car-bus"></i></span>
          </div>
        </div>
        <div class="alis-noktasi-wrap">
          <div class="alis-nokta-tarih"><span>Alış Tarihi : </span> {{ transfer.a_tarih }}</div>
          <div class="alis-noktasi-content">
            <div class="alis-noktasi">
              <span>{{ transfer.nereden[defaultDil] }}</span>
            </div>
            <div class="ortalama-sure">
              <div class="ortalam-sure-text">
                <span><i class="fad fa-clock"></i> {{ transfer.sure }}</span>
                <span>Ortalama Süre</span>
              </div>
            </div>
            <div class="alis-noktasi">
              <span>{{ transfer.nereye[defaultDil] }}</span>
            </div>
          </div>
        </div>
        <div class="alis-noktasi-wrap" v-if="transfer.tipi == 'gelis_gidis' && transfer.d_tarih != null">
          <div class="alis-nokta-tarih"><span>Dönüş Tarihi : </span> {{ transfer.d_tarih }}</div>
          <div class="alis-noktasi-content">
            <div class="alis-noktasi">
              <span>{{ transfer.nereye[defaultDil] }}</span>
            </div>
            <div class="ortalama-sure">
              <div class="ortalam-sure-text">
                <span> <i class="fad fa-clock"></i> {{ transfer.sure }}</span>
                <span> Ortalama Süre</span>
              </div>
            </div>
            <div class="alis-noktasi">
              <span>{{ transfer.nereden[defaultDil] }}</span>
            </div>
          </div>
        </div>
        <div class="trans-footer">
          <div class="trans-price" v-if="transfer.tipi == 'gelis_gidis' && transfer.d_tarih != null">
            {{ (product.fiyat * 2) | exc(transfer.kur_turu) }} {{ transfer.kur_turu }}
          </div>
          <div class="trans-price" v-else>{{ product.fiyat | exc(transfer.kur_turu) }} {{ transfer.kur_turu }}</div>
          <div class="trans-selector">
            <b-button squared block variant="warning" @click="handlerRezervasyonYap(product, false)">
              <i class="fad fa-cart-plus"></i> Sepete Ekle
            </b-button>
            <b-button squared block variant="success" @click="handlerRezervasyonYap(product, true)">
              <i class="fad fa-bell-on"></i> Rezervasyon Yap
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { ref, defineComponent, computed, toRefs } from '@vue/composition-api';
import { registerNumber, registerTicketNumber } from '../../../../../api/helpers/variables/variables';
import { urun_toplam, kdv_hesapla, exChange } from '@/libs/global';
export default defineComponent({
  props: {
    product: {
      type: Object,
      required: true,
    },
    transfer: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const toast = useToast();
    const { product, transfer } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.sepet = computed(() => store.getters.getDirekRezervasyon);
    expo.exhange = computed(() => store.getters.getExchange);
    const kurlar = computed(() => store.getters.getKurListesi);
    expo.form = ref({
      urun_k_no: null,
      bilet_no: null,
      ucus_saat: '',
      ucus_no: null,
      urun_tipi: 'transfer',
      urun_kodu: null,
      baslik: null,
      pax: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
      },
      bagaj: null,
      gorseller: null,
      alis_tarih: null,
      saat_hesapla: null,
      bolge_alis: null,
      nereden: null,
      nereye: null,
      nereden_type: null,
      nereye_type: null,
      nereden_bolge_text: null,
      nereye_bolge_text: null,
      kur_turu: null,
      kdv_turu: null,
      kdv_oran: null,
      ekstra_hizmet_k_no: [],
      ekstra_hizmetler: [],
      sabit_toplam: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kdv_toplam: 0,
        deposito: 0,
        genel_toplam: 0,
      },
    });

    expo.handlerKampanyaOrani = computed(() => {
      return (val) => {
        return val.indirim_turu == 'yuzde'
          ? `${val.indirim_tutari} %`
          : `${exChange(val.indirim_tutari, product.value.kur_turu).toFixed(2)} ${expo.exchange.value.banka_kodu}`;
      };
    });

    expo.kampanyaFiyatHesapla = computed(() => {
      return (price) => {
        const kampanya = product.value.kampanya;
        let fiyat = 0;
        if (kampanya) {
          if (kampanya.indirim_turu == 'yuzde') {
            const indirim = (price / 100) * kampanya.indirim_tutari;
            fiyat = price - indirim;
          } else {
            fiyat = price - kampanya.indirim_tutari;
          }
        } else {
          fiyat = price;
        }

        return fiyat;
      };
    });

    expo.handlerRezervasyonYap = (payload, type) => {
      let sabit_kur = kurlar.value.find((x) => x.banka_kodu == transfer.value.kur_turu);

      expo.sepet.value.k_no = registerNumber('REZ');
      expo.sepet.value.lang = expo.defaultDil.value;
      expo.sepet.value.odeme_tipi = 'hepsi';
      expo.sepet.value.odeme_tipi_kontrol = 'hepsi';

      expo.sepet.value.secilen_kur = transfer.value.kur_turu;
      expo.sepet.value.secilen_kur_fiyat = sabit_kur.satis;

      expo.sepet.value.sabit_kur = transfer.value.kur_turu;
      expo.sepet.value.sabit_kur_fiyat = sabit_kur.satis;
      expo.sepet.value.kdv_turu = transfer.value.kdv_turu;
      expo.sepet.value.kdv_oran = transfer.value.kdv_oran;
      expo.sepet.value.odeme_yontemi = 'kredi_karti';

      let baslik = {};

      Object.keys(payload.icerik).map((el) => {
        baslik[el] = payload.icerik[el].baslik;
      });

      const kdv_toplam = transfer.value.kdv_turu == 'haric' ? kdv_hesapla(parseFloat(payload.fiyat), transfer.value.kdv_oran) : 0;

      expo.form.value = {
        urun_k_no: payload.k_no,
        bilet_no: registerTicketNumber('TX'),
        ucus_saat: '',
        ucus_no: null,
        urun_tipi: 'transfer',
        urun_kodu: null,
        baslik: baslik,
        kapasite: payload.kapasite,
        bagaj: payload.bagaj,
        gorseller: payload.gorseller.length && payload.gorseller[0],
        rezervasyon_tarihi: {
          giris: transfer.value.alis_tarih,
          cikis: null,
        },
        saat_hesapla: transfer.value.a_saat_hesapla,
        bolge_alis: transfer.value.bolge_alis,
        nereden: transfer.value.nereden,
        nereye: transfer.value.nereye,
        nereden_type: transfer.value.nereden_type,
        nereye_type: transfer.value.nereye_type,
        nereden_bolge_text: transfer.value.hotel_nereden_bolge_text,
        nereye_bolge_text: transfer.value.hotel_nereye_bolge_text,
        pax: transfer.value.pax,
        kur_turu: transfer.value.kur_turu,
        kdv_turu: transfer.value.kdv_turu,
        kdv_oran: transfer.value.kdv_oran,
        ekstra_hizmet_sec: false,
        ekstra_hizmet_k_no: transfer.value.ekstra_hizmet_k_no,
        ekstra_hizmetler: [],
        sabit_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: parseFloat(payload.fiyat),
          kdv_toplam: kdv_toplam,
          deposito: 0,
          genel_toplam: parseFloat(payload.fiyat) + kdv_toplam,
        },
        kur_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: 0,
          ekstra_toplam: 0,
          kdv_toplam: kdv_toplam,
          deposito: 0,
          genel_toplam: 0 + kdv_toplam,
        },
      };

      store.commit('SET_SEPET_DIREK_URUN_PUSH', expo.form.value);

      if (transfer.value.tipi == 'gelis_gidis') {
        expo.form.value = {
          urun_k_no: payload.k_no,
          bilet_no: registerTicketNumber('TX'),
          ucus_saat: '',
          ucus_no: null,
          urun_tipi: 'transfer',
          urun_kodu: null,
          baslik: baslik,
          kapasite: payload.kapasite,
          bagaj: payload.bagaj,
          gorseller: payload.gorseller.length && payload.gorseller[0],
          nereden: transfer.value.nereye,
          nereye: transfer.value.nereden,
          nereden_type: transfer.value.nereden_type,
          nereye_type: transfer.value.nereye_type,
          nereden_bolge_text: transfer.value.hotel_nereden_bolge_text,
          nereye_bolge_text: transfer.value.hotel_nereye_bolge_text,
          rezervasyon_tarihi: {
            giris: transfer.value.donus_tarih,
            cikis: null,
          },
          saat_hesapla: transfer.value.d_saat_hesapla,
          bolge_alis: transfer.value.bolge_alis,
          bugun_satis_fiyat: parseFloat(payload.fiyat),
          pax: transfer.value.pax,
          kur_turu: transfer.value.kur_turu,
          kdv_turu: transfer.value.kdv_turu,
          kdv_oran: transfer.value.kdv_oran,
          ekstra_hizmet_sec: false,
          ekstra_hizmet_k_no: transfer.value.ekstra_hizmet_k_no,
          ekstra_hizmetler: [],
          sabit_toplam: {
            yetiskin: 0,
            cocuk: 0,
            bebek: 0,
            toplam: parseFloat(payload.fiyat),
            ekstra_toplam: 0,
            kdv_toplam: kdv_toplam,
            deposito: 0,
            genel_toplam: parseFloat(payload.fiyat) + kdv_toplam,
          },
          kur_toplam: {
            yetiskin: 0,
            cocuk: 0,
            bebek: 0,
            toplam: 0,
            ekstra_toplam: 0,
            kdv_toplam: kdv_toplam,
            deposito: 0,
            genel_toplam: 0 + kdv_toplam,
          },
        };

        store.commit('SET_SEPET_DIREK_URUN_PUSH', expo.form.value);
      }

      expo.sepet.value.rezervasyon_toplam.urun_toplam =
        transfer.value.tipi == 'tek_yon' ? parseFloat(payload.fiyat) : parseFloat(payload.fiyat * 2);

      toast.success('Ürün sepete eklendi.', {
        position: 'top-center',
      });

      if (type) {
        router.push({ name: 'rezervasyon-sepet' });
      }
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.transfer-wrap {
  position: relative;
  display: flex;
  border: 1px solid #ddd;
  .transfer-left {
    border-right: 1px solid #ddd;
    padding: 5px;
  }

  .transfer-right {
    position: relative;
    display: block;
    width: 100%;
    font-size: 14px;
    &__header {
      padding: 10px;
      position: relative;
      display: flex;
      border-bottom: 1px solid #ddd;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .content-title {
        font-size: 16px;
        font-weight: 600;
        padding-right: 10px;
      }
      .special {
        padding-top: 5px;
        span {
          background: #1876d2;
          color: #fff;
          margin-right: 10px;
          padding: 5px 10px;
        }
      }
    }
    &__body {
      .body-wrap {
        .transfer-tarih {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          span {
            font-weight: 600;
            padding-right: 10px;
          }
        }
        .transfer-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ddd;
          padding: 5px;
          &:before {
            content: '\f3c5';
            font-family: 'Font Awesome 5 Pro';
            padding: 5px;
            font-size: 20px;
            color: #1876d2;
          }
          .ortalama-sure {
            min-width: 100px;
            color: #1876d2;
            flex-grow: 1;
            font-size: 16px;
            margin-left: 10px;
            margin-right: 10px;
            position: relative;
            text-align: center;
            &:before {
              background-color: #1876d2;
              content: '';
              height: 1px;
              left: 0;
              margin-top: -1px;
              position: absolute;
              top: 50%;
              width: 50%;
            }
            &:after {
              background-color: #1876d2;
              content: '';
              height: 1px;
              right: 0;
              margin-top: -1px;
              position: absolute;
              top: 50%;
              width: 50%;
            }
            .ortalam-sure-text {
              // background: #fff;
              flex-direction: column;
              display: flex;
              padding: 0 10px;
              position: relative;
              z-index: 1;
            }
          }
          .alis-noktasi {
            display: flex;
            flex-direction: column;
            min-width: 200px;
            span {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      .trans-price {
        font-size: 18px;
        font-weight: 600;
        color: #1876d2;
      }
    }
  }
}
</style>
