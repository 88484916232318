<template>
  <div class="product-search p-0 m-0">
    <div class="slide-group">
      <b-form @submit.stop.prevent="$emit('handlerFilter', true)">
        <b-tabs content-class="mt-3" align="center">
          <b-tab lazy active @click="handlerSelectProduct('urun')">
            <template #title>
              <i class="fad fa-list-alt"></i>
              ÜRÜNLER
            </template>

            <urun-filtrele :filter="filter" @filtered="$emit('filtered', $event)" />
          </b-tab>
          <b-tab lazy @click="handlerSelectProduct('transfer')">
            <template #title>
              <i class="fad fa-plane-alt"></i>
              TRANSFERLER
            </template>

            <TransferFiltrele :filter="filter" @filtered="$emit('filtered', $event)" />
          </b-tab>
        </b-tabs>
      </b-form>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import UrunFiltrele from './UrunFiltrele.vue';
import TransferFiltrele from './TransferFiltrele.vue';
import { defineComponent, ref, computed, toRefs } from '@vue/composition-api';
export default defineComponent({
  components: { TransferFiltrele, UrunFiltrele },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { filter } = toRefs(props);

    expo.handlerSelectProduct = (event) => {
      filter.value.filtre_turu = event;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
