<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-2 border-bottom">
      <h5 class="font-weight-bold">Kiralama Süresi :</h5>
      <h5 class="text-primary font-weight-bold">{{ form.toplam_gun }} Gün</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
      <h5 class="font-weight-bold">{{ form.kdv_turu == 'dahil' ? 'Genel Toplam' : 'Ara Toplam' }} :</h5>
      <h5 class="text-primary font-weight-bold">{{ form.sabit_toplam.toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
      <h5>
        KDV Toplam ( <b> % {{ form.kdv_oran }} </b> ) :
      </h5>
      <h5 class="text-primary font-weight-bold" v-if="form.kdv_turu == 'haric'">
        {{ form.sabit_toplam.kdv_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
      </h5>
      <h5 class="text-primary font-weight-bold" v-else>Tüm Vergiler Dahil</h5>
    </div>

    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom" v-if="form.kdv_turu == 'haric'">
      <h5 class="font-weight-bold">Genel Toplam :</h5>
      <h5 class="text-primary font-weight-bold">
        {{ form.sabit_toplam.genel_toplam | exc(form.kur_turu) }} {{ exchange.banka_kodu }}
      </h5>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, computed } from '@vue/composition-api';
import moment from 'moment';
export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};

    expo.exchange = computed(() => store.getters.getExchange);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
