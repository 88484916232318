import { render, staticRenderFns } from "./rezervasyonUrunler.vue?vue&type=template&id=af88651c&scoped=true&"
import script from "./rezervasyonUrunler.vue?vue&type=script&lang=js&"
export * from "./rezervasyonUrunler.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af88651c",
  null
  
)

export default component.exports