<template>
  <div>
    <b-card-text>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(handlerSepeteEkle(false))">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center justify-content-between">
                <i class="fad fa-user-tag fa-2x pr-4 text-primary" style="min-width: 50px" />
                <RezervasyonSelectPax :form="form" />
              </div>
            </b-col>
            <b-col cols="12">
              <div class="d-flex justify-content-between">
                <i class="fad fa-calendar-alt fa-2x pt-2 pr-4 text-primary" style="min-width: 50px" />
                <validation-provider
                  name="Rezervasyon Tarihi"
                  class="flat-input w-100"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <flat-pickr
                    class="form-control form-control-lg w-100 rounded-0 border border-primary mb-4 bg-white"
                    v-model="date"
                    :config="config"
                    :events="config.events"
                    placeholder="Rezervasyon Tarihi"
                    :class="{ 'is-invalid': !!validationContext.errors[0] }"
                    :state="getValidationState(validationContext)"
                    @on-change="musaitlikTakvimChange"
                    @on-day-create="listenToOnDayCreateEvent"
                  />
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" v-if="product.seans_saatleri.length">
              <div class="d-flex justify-content-between pb-4">
                <i class="fad fa-alarm-clock fa-2x pt-2 pr-4 text-primary" style="min-width: 50px" />

                <validation-provider
                  name="Seans Saati"
                  class="w-100"
                  :rules="{ required: product.seans_saatleri.length }"
                  v-slot="validationContext"
                >
                  <b-form-select
                    class="form-control form-control-lg rounded-0 border border-primary text-black"
                    v-model="form.seans_saati"
                    aria-describedby="input-1-live-feedback"
                    :state="getValidationState(validationContext)"
                    size="lg"
                  >
                    <b-form-select-option :value="null"> Seans Saati </b-form-select-option>
                    <b-form-select-option v-for="(item, i) in product.seans_saatleri" :key="i" :value="item">
                      {{ item }}
                    </b-form-select-option>
                  </b-form-select>
                </validation-provider>
              </div>
            </b-col>

            <b-col cols="12">
              <UrunRezervasyonBilgiTur :product="product" :form="form" />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-text>
    <b-card-footer class="p-0 border-0">
      <div class="w-100">
        <b-button-group class="w-100">
          <b-button type="submit" squared variant="warning" class="w-50" @click="handlerSepeteEkle(false)">
            <i class="fad fa-cart-plus"></i>
            Sepete Ekle
          </b-button>
          <b-button squared variant="success" class="w-50" @click="handlerSepeteEkle(true)">
            <i class="fad fa-bell-on"></i>
            Rezervasyon Yap
          </b-button>
        </b-button-group>
      </div>
    </b-card-footer>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import { defineComponent, ref, toRefs, watch, computed } from '@vue/composition-api';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import RezervasyonSelectPax from '../ekle/RezervasyonSelectPax.vue';
import UrunRezervasyonBilgiTur from './UrunRezervasyonBilgiTur.vue';

import { registerNumber, registerTicketNumber } from '../../../../../api/helpers/variables/variables';

export default defineComponent({
  components: {
    RezervasyonSelectPax,
    UrunRezervasyonBilgiTur,
    flatPickr,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { router } = useRouter();
    const { product } = toRefs(props);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.stokBirimleri = computed(() => store.getters.getStokBirimler);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.rezervasyon = computed(() => store.getters.getDirekRezervasyon);
    expo.show = ref(false);

    expo.date = ref(null);
    expo.config = ref({
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: window.innerWidth < 600 ? 1 : 2,
      mode: 'single',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      locale: Turkish, // locale for this instance only
      disableMobile: true,
    });

    expo.form = ref({
      urun_k_no: null,
      bilet_no: null,
      urun_tipi: 'tur',
      urun_kodu: null,
      baslik: null,
      gorseller: null,
      rezervasyon_tarihi: {
        giris: null,
        cikis: null,
      },
      pax: {
        yetiskin: 1,
        cocuk: 0,
        bebek: 0,
      },
      seans_saati: null,
      bolge_alis_saati: null,
      bugun_satis_fiyat: null,
      kur_turu: null,
      kdv_turu: null,
      kdv_oran: 0,
      kampanya: {},
      aktif_aylar: [],
      aktif_gunler: [],
      seans_saatleri: [],
      ekstra_hizmet_sec: false,
      ekstra_hizmet_k_no: [],
      ekstra_hizmetler: [],
      odeme_turu: 'hepsi', // hepsi,on_odeme
      sabit_toplam: {
        yetiskin_fiyat: 0,
        cocuk_fiyat: 0,
        bebek_fiyat: 0,
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kdv_toplam: 0,
        deposito: 0,
        genel_toplam: 0,
      },
      kur_toplam: {
        yetiskin_fiyat: 0,
        cocuk_fiyat: 0,
        bebek_fiyat: 0,
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kdv_toplam: 0,
        deposito: 0,
        genel_toplam: 0,
      },
    });

    expo.listenToOnDayCreateEvent = (dObj, dStr, fp, dayElem) => {
      fp.config.disable.push((dObj) => {
        if (dObj.getDay() != undefined && dObj.getMonth() != undefined) {
          //1 pazartesi 2 salı 3 carsamba 4 persembe 5 cuma 6 cumartesi 0 pazar
          let oca, sub, mar, nis, may, haz, tem, agu, eyl, eki, kas, ara;
          if (product.value.aktif_aylar.indexOf('ocak') > -1) oca = 0;
          if (product.value.aktif_aylar.indexOf('subat') > -1) sub = 1;
          if (product.value.aktif_aylar.indexOf('mart') > -1) mar = 2;
          if (product.value.aktif_aylar.indexOf('nisan') > -1) nis = 3;
          if (product.value.aktif_aylar.indexOf('mayis') > -1) may = 4;
          if (product.value.aktif_aylar.indexOf('haziran') > -1) haz = 5;
          if (product.value.aktif_aylar.indexOf('temmuz') > -1) tem = 6;
          if (product.value.aktif_aylar.indexOf('agustos') > -1) agu = 7;
          if (product.value.aktif_aylar.indexOf('eylul') > -1) eyl = 8;
          if (product.value.aktif_aylar.indexOf('ekim') > -1) eki = 9;
          if (product.value.aktif_aylar.indexOf('kasim') > -1) kas = 10;
          if (product.value.aktif_aylar.indexOf('aralik') > -1) ara = 11;

          //Günler
          let pzt, sal, car, per, cum, cumrts, paz;
          if (product.value.aktif_gunler.indexOf('pazar') >= 0) paz = 0;
          if (product.value.aktif_gunler.indexOf('pazartesi') >= 0) pzt = 1;
          if (product.value.aktif_gunler.indexOf('sali') >= 0) sal = 2;
          if (product.value.aktif_gunler.indexOf('carsamba') >= 0) car = 3;
          if (product.value.aktif_gunler.indexOf('persembe') >= 0) per = 4;
          if (product.value.aktif_gunler.indexOf('cuma') >= 0) cum = 5;
          if (product.value.aktif_gunler.indexOf('cumartesi') >= 0) cumrts = 6;

          return (
            (dObj.getMonth() != oca &&
              dObj.getMonth() != sub &&
              dObj.getMonth() != mar &&
              dObj.getMonth() != nis &&
              dObj.getMonth() != may &&
              dObj.getMonth() != haz &&
              dObj.getMonth() != tem &&
              dObj.getMonth() != agu &&
              dObj.getMonth() != eyl &&
              dObj.getMonth() != eki &&
              dObj.getMonth() != kas &&
              dObj.getMonth() != ara) ||
            (dObj.getDay() != paz &&
              dObj.getDay() != pzt &&
              dObj.getDay() != sal &&
              dObj.getDay() != car &&
              dObj.getDay() != per &&
              dObj.getDay() != cum &&
              dObj.getDay() != cumrts)
          );
        }
      });
    };

    expo.musaitlikTakvimChange = (event) => {
      if (event[0] != null) {
        expo.form.value.rezervasyon_tarihi.giris = moment(String(event[0])).format('YYYY-MM-DD');
      }
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.handlerSepeteEkle = (type) => {
      const urunVarmi = expo.rezervasyon.value.sepet_urunler.find(
        (x) =>
          x.k_no == product.value.k_no &&
          x.rezervasyon_tarihi == expo.form.value.rezervasyon_tarihi &&
          x.seans_saati == expo.form.value.seans_saati
      );

      if (
        expo.form.value.rezervasyon_tarihi.giris == null ||
        (product.value.seans_saatleri.length && expo.form.value.seans_saati == null)
      ) {
        toast.warning('Lütfen Zorunlu Alanları Doldurunuz !', {
          position: 'top-center',
        });
      } else if (urunVarmi) {
        toast.warning('Bu Ürün Sepete Eklenmiştir.', {
          position: 'top-center',
        });
      } else {
        let sabit_kur = expo.kurlar.value.find((x) => x.banka_kodu == product.value.kur_turu);

        expo.rezervasyon.value.k_no = registerNumber('REZ');
        expo.rezervasyon.value.sabit_kur = sabit_kur.banka_kodu;
        expo.rezervasyon.value.sabit_kur_fiyat = sabit_kur.satis;
        expo.rezervasyon.value.odeme_tipi = 'hepsi';
        expo.rezervasyon.value.odeme_tipi_kontrol = 'hepsi';

        let baslik = {};
        let slug = {};

        Object.keys(product.value.icerik).map((el) => {
          baslik[el] = product.value.icerik[el].baslik;
          slug[el] = product.value.icerik[el].slug;
        });

        expo.form.value.urun_k_no = product.value.k_no;
        expo.form.value.urun_tipi = 'tur';
        expo.form.value.bilet_no = registerTicketNumber('TX');
        expo.form.value.urun_kodu = product.value.urun_kodu;
        expo.form.value.baslik = baslik;
        expo.form.value.slug = slug;
        expo.form.value.yas = product.value.yas;
        expo.form.value.seans_saatleri = product.value.seans_saatleri;
        expo.form.value.gorseller = product.value.gorseller.length && product.value.gorseller[0];
        expo.form.value.kategori = product.value.kategori[0].slug;
        expo.form.value.fiyatlandirma = product.value.fiyatlandirma;
        expo.form.value.bugun_satis_fiyat = product.value.bugun_satis_fiyat;
        expo.form.value.kur_turu = product.value.kur_turu;
        expo.form.value.kdv_turu = product.value.kdv_turu;
        expo.form.value.kdv_oran = product.value.kdv_oran;
        expo.form.value.ekstra_hizmet_k_no = product.value.ekstra_hizmet_k_no;

        if (product.value.kampanya_k_no) {
          expo.form.value.kampanya = {
            indirim_turu: product.value.kampanya.indirim_turu,
            indirim_tutari: product.value.kampanya.indirim_tutari,
          };
        }

        store.commit('SET_SEPET_DIREK_URUN_PUSH', expo.form.value);

        toast.success('Ürün Sepete Eklendi.', {
          position: 'top-center',
        });

        if (type) {
          router.push({ name: 'rezervasyon-sepet' });
        }
      }
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
