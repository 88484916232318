<template>
  <div>
    <b-card-text>
      <b-overlay :show="show">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(handlerSepeteEkle(false))">
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center justify-content-between pb-3">
                  <b-form-group class="w-100" label="Teslim Alma & Etme Bölgesi">
                    <b-input-group>
                      <b-input-group-append class="w-50">
                        <v-select
                          v-model="formBolge.alis_bolgesi"
                          :options="bolgeler"
                          :reduce="(bolge) => bolge"
                          label="title"
                          :clearable="false"
                          placeholder="Alış Bölgesi"
                          class="invoice-filter-select d-block select-size-lg w-100 ml-1"
                          aria-describedby="input-1-live-feedback"
                          :filterable="false"
                        >
                          <template slot="selected-option" slot-scope="option">
                            {{ option.icerik[defaultDil].baslik }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.icerik[defaultDil].baslik }}
                          </template>
                        </v-select>
                      </b-input-group-append>
                      <b-input-group-prepend class="w-50">
                        <v-select
                          v-model="formBolge.teslim_bolgesi"
                          :options="bolgeler"
                          :reduce="(bolge) => bolge"
                          label="title"
                          :clearable="false"
                          placeholder="Teslim Bölgesi"
                          class="invoice-filter-select d-block select-size-lg w-100 ml-1"
                          aria-describedby="input-1-live-feedback"
                          :filterable="false"
                        >
                          <template slot="selected-option" slot-scope="option">
                            {{ option.icerik[defaultDil].baslik }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.icerik[defaultDil].baslik }}
                          </template>
                        </v-select>
                      </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>
              <b-col cols="12">
                <div class="d-flex justify-content-between">
                  <b-form-group class="w-100" label="Teslim Alma & Etme Tarihi">
                    <flat-pickr
                      class="form-control form-control-lg rounded-0 border mb-4 bg-white"
                      v-model="date"
                      :config="config"
                      :events="config.events"
                      placeholder="Teslim Alma & Etme Tarihi"
                      @on-day-create="listenToOnDayCreateEvent"
                    />
                  </b-form-group>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center">
                  <b-form-group class="w-100" label="Teslim Alma & Etme Saati">
                    <b-input-group>
                      <b-input-group-append class="w-50">
                        <flat-pickr
                          class="form-control form-control-lg mr-1 rounded-0 border mb-4 bg-white"
                          v-model="form.alis_saat"
                          :config="timeConfig"
                          :events="config.events"
                          placeholder="Teslim Alma Saati"
                        />
                      </b-input-group-append>
                      <b-input-group-prepend class="w-50">
                        <flat-pickr
                          class="form-control form-control-lg ml-1 rounded-0 border mb-4 bg-white"
                          v-model="form.teslim_saat"
                          :config="timeConfig"
                          :events="config.events"
                          placeholder="Teslim Etme Saati"
                        />
                      </b-input-group-prepend>
                    </b-input-group>
                  </b-form-group>
                </div>
              </b-col>

              <b-col cols="12">
                <UrunRezervasyonBilgiArac v-if="promosyonMusaitmi" :product="product" :form="form" />
              </b-col>
              <b-col cols="12" v-if="!promosyonMusaitmi">
                <b-button block squared variant="info" @click="handlerRezervasyonSorgula">
                  <i class="fad fa-question-circle"></i> Sorgula
                </b-button>
              </b-col>
              <b-col cols="12" v-else>
                <b-button-group class="w-100 pb-1">
                  <b-button class="w-50 mr-1" type="submit" squared variant="warning">
                    <i class="fad fa-cart-plus"></i>
                    Sepete Ekle
                  </b-button>
                  <b-button class="w-50" squared variant="success" @click="handlerSepeteEkle(true)">
                    <i class="fad fa-bell-on"></i>
                    Rezervasyon Yap
                  </b-button>
                </b-button-group>
                <b-button block size="sm" squared variant="outline-danger" @click="handlerRezervasyonTemizle">
                  <i class="fad fa-trash"></i>
                  Temizle
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card-text>
    <!-- <b-card-footer class="p-0 border-0">
      <div class="w-100">
        <b-button-group class="w-100">
          <b-button type="submit" squared variant="warning" class="w-50" @click="handlerSepeteEkle(false)">
            <i class="fad fa-cart-plus"></i>
            Sepete Ekle
          </b-button>
          <b-button squared variant="success" class="w-50" @click="handlerSepeteEkle(true)">
            <i class="fad fa-bell-on"></i>
            Rezervasyon Yap
          </b-button>
        </b-button-group>
      </div>
    </b-card-footer> -->
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import { defineComponent, ref, toRefs, watch, computed, onMounted } from '@vue/composition-api';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import RezervasyonSelectPax from '../ekle/RezervasyonSelectPax.vue';
import UrunRezervasyonBilgiArac from './UrunRezervasyonBilgiArac.vue';

import { registerNumber, registerTicketNumber } from '../../../../../api/helpers/variables/variables';
import axiosIns from '@/libs/axios';

export default defineComponent({
  components: {
    vSelect,
    RezervasyonSelectPax,
    UrunRezervasyonBilgiArac,
    flatPickr,
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { router } = useRouter();
    const { product } = toRefs(props);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.rezervasyon = computed(() => store.getters.getDirekRezervasyon);
    expo.bolgeler = computed(() =>
      store.getters.getTumBolgeler.filter((x) => x.bolge_k_no != null && x.urun_grubu.includes('arac_kira'))
    );
    expo.show = ref(false);

    expo.date = ref(null);

    expo.formBolge = ref({
      alis_bolgesi: null,
      teslim_bolgesi: null,
    });
    expo.form = ref({
      urun_k_no: null,
      bilet_no: null,
      urun_tipi: 'arac_kira',
      urun_kodu: null,
      baslik: null,
      gorseller: null,
      rezervasyon_tarihi: {
        giris: null,
        cikis: null,
      },
      pax: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
      },
      alis_bolgesi: null,
      teslim_bolgesi: null,
      alis_saat: '09:00',
      teslim_saat: '09:00',
      bugun_satis_fiyat: null,
      kur_turu: null,
      kdv_turu: null,
      kdv_oran: 0,
      ekstra_hizmet_sec: false,
      ekstra_hizmet_k_no: [],
      ekstra_hizmetler: [],
      kampanya: {},
      toplam_gun: 0,
      odeme_turu: 'hepsi', // hepsi,on_odeme
      sabit_toplam: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kdv_toplam: 0,
        deposito: 0,
        genel_toplam: 0,
      },
      kur_toplam: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kdv_toplam: 0,
        deposito: 0,
        genel_toplam: 0,
      },
    });

    expo.config = ref({
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: window.innerWidth < 600 ? 1 : 2,
      mode: 'range',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      enable: [],
      locale: Turkish,
    });

    expo.timeConfig = ref({
      enableTime: true,
      noCalendar: true,
      dateFormat: 'H:i',
      time_24hr: true,
      minuteIncrement: 30,
      locale: Turkish,
    });

    const arrRezervasyonBas = ref([]);
    const arrRezervasyonBit = ref([]);
    const arrRezervasyonDol = ref([]);

    expo.listenToOnDayCreateEvent = (dObj, dStr, fp, dayElem) => {
      // yapılan eylemlere gore takvim düzenlemesi yapılacak
      // console.log(dObj + ' ' + dStr + ' ' + fp);
      if (arrRezervasyonDol.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bas-bit-gun');
      }
      if (arrRezervasyonBas.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bas-gun');
      }
      if (arrRezervasyonBit.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bit-gun');
      }
    };

    expo.promosyonMusaitmi = computed(() => {
      if (expo.form.value.toplam_gun > 0) {
        return true;
      } else {
        return false;
      }
    });

    expo.kampanyaFiyatHesapla = (price) => {
      const kampanya = product.value.kampanya;

      let fiyat = 0;
      if (kampanya) {
        if (kampanya.indirim_turu == 'yuzde') {
          const indirim = (price / 100) * kampanya.indirim_tutari;
          fiyat = price - indirim;
        } else {
          fiyat = price - kampanya.indirim_tutari;
        }
      } else {
        fiyat = price;
      }

      return fiyat;
    };

    expo.handlerRezervasyonSorgula = () => {
      expo.show.value = true;

      if (
        expo.date.value &&
        expo.formBolge.value.alis_bolgesi &&
        expo.formBolge.value.teslim_bolgesi &&
        expo.form.value.alis_saat &&
        expo.form.value.teslim_saat
      ) {
        const tarih = expo.date.value.split(' - ');

        const payload = {
          urun_k_no: product.value.k_no,
          alis_bolgesi: expo.formBolge.value.alis_bolgesi,
          teslim_bolgesi: expo.formBolge.value.teslim_bolgesi,
          rezervasyon_tarihi: {
            giris: moment(tarih[0] + ' ' + expo.form.value.alis_saat, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
            cikis: moment(tarih[1] + ' ' + expo.form.value.teslim_saat, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
          },
        };

        axiosIns.post('/travel/rezervasyon-sorgula-villa-yat-post', payload).then((res) => {
          if (res.data.success) {
            let sabit_kur = expo.kurlar.value.find((x) => x.banka_kodu == res.data.kur_turu);
            expo.rezervasyon.value.k_no = registerNumber('REZ');
            expo.rezervasyon.value.lang = expo.defaultDil.value;
            expo.rezervasyon.value.sabit_kur = res.data.kur_turu;
            expo.rezervasyon.value.sabit_kur_fiyat = sabit_kur.satis;

            let alisBolge = {};
            let teslimBolge = {};

            Object.keys(expo.formBolge.value.alis_bolgesi.icerik).map((el) => {
              alisBolge[el] = expo.formBolge.value.alis_bolgesi.icerik[el].baslik;
            });

            Object.keys(expo.formBolge.value.teslim_bolgesi.icerik).map((el) => {
              teslimBolge[el] = expo.formBolge.value.teslim_bolgesi.icerik[el].baslik;
            });

            expo.form.value.urun_k_no = product.value.k_no;
            expo.form.value.alis_bolgesi = alisBolge;
            expo.form.value.teslim_bolgesi = teslimBolge;
            expo.form.value.rezervasyon_tarihi = payload.rezervasyon_tarihi;
            expo.form.value.toplam_gun = res.data.toplam_gun;
            expo.form.value.ekstra_hizmet_k_no = product.value.ekstra_hizmet_k_no;
            expo.form.value.satis_yontemi = product.value.satis_yontemi;
            expo.form.value.kur_turu = res.data.kur_turu;
            expo.form.value.kdv_turu = res.data.kdv_turu;
            expo.form.value.kdv_oran = res.data.kdv_oran;

            let toplam = expo.kampanyaFiyatHesapla(res.data.toplam_tutar);

            let kdv_toplam = product.value.kdv_turu == 'haric' ? global.kdv_hesapla(toplam, product.value.kdv_oran) : 0,
              genel_toplam = toplam + kdv_toplam;

            expo.form.value.sabit_toplam = {
              yetiskin: 0,
              cocuk: 0,
              bebek: 0,
              toplam: toplam,
              deposito: 0,
              kdv_toplam: kdv_toplam,
              genel_toplam: genel_toplam,
            };

            if (res.data.kur_turu != expo.exchange.value.banka_kodu) {
              expo.form.value.kur_toplam = {
                yetiskin: 0,
                cocuk: 0,
                bebek: 0,
                toplam: global.exChange(toplam, res.data.kur_turu),
                deposito: 0,
                kdv_toplam: global.exChange(kdv_toplam, res.data.kur_turu),
                genel_toplam: global.exChange(genel_toplam, res.data.kur_turu),
              };
            }

            const gun_fiyatlar = [];

            res.data.fiyatlar.forEach((x) => {
              const day = gun_fiyatlar.findIndex((z) => z.fiyat == x);
              if (day != -1) {
                gun_fiyatlar[day].gun = gun_fiyatlar[day].gun + 1;
              } else {
                gun_fiyatlar.push({ fiyat: x, gun: 1 });
              }
            });

            expo.rezervasyon.value.gun_fiyatlar = gun_fiyatlar;

            expo.show.value = false;
          } else {
            if (res.data.errorcode == 'min_konaklama') {
              toast.error(context.i18n.t('minimumKonaklamaHata', [product.value.minimum_konaklama]), {
                position: 'bottom-left',
              });
            } else if (res.data.errorcode == 'fiyat_yok') {
              toast.error(context.i18n.t('secilenTarihFiyatYok'), {
                position: 'bottom-left',
              });
            }

            expo.show.value = false;
          }
        });
      } else {
        toast.warning(context.i18n.t('zorunluAlanlariDoldurunuz'), {
          position: 'top-center',
        });
      }
    };

    expo.handlerSepeteEkle = (type) => {
      const urunVarmi = expo.rezervasyon.value.sepet_urunler.find(
        (x) =>
          x.k_no == product.value.k_no &&
          x.rezervasyon_tarihi == expo.form.value.rezervasyon_tarihi &&
          x.alis_saat == expo.form.value.alis_saat &&
          x.teslim_saat == expo.form.value.teslim_saat
      );

      if (
        expo.form.value.alis_bolgesi == null ||
        expo.form.value.teslim_bolgesi == null ||
        expo.form.value.alis_saat == null ||
        expo.form.value.teslim_saat == null
      ) {
        toast.warning('Zorunlu alanları doldurunuz', {
          position: 'top-center',
        });
      } else if (urunVarmi) {
        toast.warning('Bu ürün sepete eklenmiştir.', {
          position: 'top-center',
        });
      } else {
        expo.rezervasyon.value.sabit_kur = product.value.kur_turu;
        expo.rezervasyon.value.odeme_tipi = 'hepsi';
        expo.rezervasyon.value.odeme_tipi_kontrol = 'hepsi';

        let baslik = {};
        let slug = {};

        Object.keys(product.value.icerik).map((el) => {
          baslik[el] = product.value.icerik[el].baslik;
          slug[el] = product.value.icerik[el].slug;
        });

        expo.form.value.k_no = product.value.k_no;
        expo.form.value.urun_tipi = 'arac_kira';
        expo.form.value.bilet_no = registerTicketNumber('TX');
        expo.form.value.urun_kodu = product.value.urun_kodu;
        expo.form.value.baslik = baslik;
        expo.form.value.slug = slug;
        expo.form.value.gorseller = product.value.gorseller.length && product.value.gorseller[0];
        expo.form.value.kategori = product.value.kategori[0].slug;
        expo.form.value.bugun_satis_fiyat = product.value.bugun_satis_fiyat;
        expo.form.value.kur_turu = product.value.kur_turu;
        expo.form.value.kdv_turu = product.value.kdv_turu;
        expo.form.value.kdv_oran = product.value.kdv_oran;
        expo.form.value.ekstra_hizmet_k_no = product.value.ekstra_hizmet_k_no;

        if (product.value.kampanya_k_no) {
          expo.form.value.kampanya = {
            indirim_turu: product.value.kampanya.indirim_turu,
            indirim_tutari: product.value.kampanya.indirim_tutari,
          };
        }

        store.commit('SET_SEPET_DIREK_URUN_PUSH', expo.form.value);

        toast.success('Ürün Sepete Eklendi.', {
          position: 'top-center',
        });

        if (type) {
          router.push({ name: 'rezervasyon-sepet' });
        }
      }
    };

    expo.handlerRezervasyonTemizle = () => {
      // store.commit('rezervasyon/SET_SEPET_DIREK_REZERVASYON_RESET');
      expo.form.value = {
        k_no: null,
        bilet_no: null,
        urun_tipi: 'arac_kira',
        urun_kodu: null,
        baslik: null,
        gorseller: null,
        rezervasyon_tarihi: null,
        pax: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
        },
        alis_bolgesi: null,
        teslim_bolgesi: null,
        alis_saat: '09:00',
        teslim_saat: '09:00',
        bugun_satis_fiyat: null,
        kur_turu: null,
        kdv_turu: null,
        kdv_oran: 0,
        kampanya: {},
        toplam_gun: 0,
        sabit_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: 0,
          ekstra_toplam: 0,
          kdv_toplam: 0,
          genel_toplam: 0,
        },
        kur_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: 0,
          ekstra_toplam: 0,
          kdv_toplam: 0,
          genel_toplam: 0,
        },
      };
      expo.data.value = null;
    };

    onMounted(() => {
      product.value.satis_fiyat.forEach((el) => {
        let bas_tarih = moment(el.bas_tarih).format('DD.MM.YYYY');
        let bit_tarih = moment(el.bit_tarih).format('DD.MM.YYYY');
        expo.config.value.enable.push({ from: bas_tarih, to: bit_tarih });
      });

      // if (product.value.k_no != expo.rezervasyon.value.urun_k_no) store.commit('rezervasyon/SET_SEPET_DIREK_REZERVASYON_RESET');
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
