<template>
  <div>
    <b-modal
      lazy
      :visible="openModal"
      @change="(val) => $emit('closeModal', false)"
      class="rounded-0"
      :title="`${product.icerik[defaultDil].baslik} Ürün Detay`"
      size="huge"
      hide-footer
      style="width: 1020px"
    >
      <b-tabs>
        <b-tab lazy title="Açıklama">
          <div v-html="product.icerik[defaultDil].aciklama" />
        </b-tab>

        <b-tab lazy title="Musaitlik">
          <flat-pickr
            v-model="date"
            :config="config"
            :events="config.events"
            @on-day-create="listenToOnDayCreateEvent"
            name="date"
            class="d-none"
          />
        </b-tab>
        <b-tab title="Özellikler" v-if="product.ozel_alanlar.length">
          <div v-for="(ozel, index) in handlerOzellikler(product.ozel_alanlar)" :key="index">
            <h6 class="font-weight-bold">{{ ozelAlanBaslik(ozel.k_no) }}</h6>
            <hr />
            <div class="row">
              <div class="col-12 col-md-4" v-for="(sub, i) in ozel.ozellik" :key="i">
                <div class="ozel-card">
                  <div class="ozel-card-title" v-if="ozelAlanSubBaslik(ozel.k_no, sub.k_no)">
                    <i
                      :class="`fad fa-${ozelAlanSubBaslik(ozel.k_no, sub.k_no).icon} pr-3 text-primary`"
                      v-if="ozelAlanSubBaslik(ozel.k_no, sub.k_no) != undefined"
                    ></i>
                    <span>{{ ozelAlanSubBaslik(ozel.k_no, sub.k_no).icerik[defaultDil].baslik }}</span>
                  </div>

                  <i v-if="sub.value == true" class="fad fa-check-double text-success"></i>
                  <i v-else-if="sub.value == false" class="fad fa-check-double text-danger"></i>
                  <span v-else class="ozel-card-value text-success font-weight-bold">{{ sub.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Fiyatlandırma" v-if="product.satis_fiyat.length">
          <ul class="product-price-list">
            <li v-for="(item, index) in product.satis_fiyat" :key="index">
              <template v-if="product.fiyat_turu == 'donemsel'">
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column">
                    <h6 class="font-weight-bold">{{ item.bas_tarih | momentText }} - {{ item.bit_tarih | momentText }}</h6>
                    <small class="min-select" v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      minimum konaklama {{ product.minimum_konaklama }} gün
                    </small>
                  </div>
                </div>
                <div
                  class="week-price d-flex flex-column"
                  :class="product.kampanya_k_no && 'text-danger'"
                  :style="product.kampanya_k_no && 'text-decoration: line-through'"
                >
                  <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                    <h4>{{ item.fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                    <p>Gecelik</p>
                  </template>
                  <template v-else>
                    <h4>{{ item.fiyat.yetiskin | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                    <p>Günlük</p>
                  </template>
                </div>
                <div class="week-price d-flex flex-column text-success" v-if="product.kampanya_k_no">
                  <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                    <h4>{{ kampanyaHesapla(item.fiyat.yetiskin) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                    <p>Gecelik</p>
                  </template>
                  <template v-else>
                    <h4>{{ kampanyaHesapla(item.fiyat.yetiskin) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                    <p>Günlük</p>
                  </template>
                </div>
              </template>
              <template v-if="product.fiyat_turu == 'bolgesel'">
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <div class="d-flex flex-column">
                    <h6 class="font-weight-bold">
                      <b-badge
                        style="font-size: 12px"
                        class="rounded-0 mr-1"
                        variant="success"
                        v-for="(bol, i) in item.bolge"
                        :key="i"
                      >
                        {{ bolgeTitle(bol) }}
                      </b-badge>
                    </h6>
                    <small class="min-select" v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      minimum konaklama {{ product.minimum_konaklama }} gün
                    </small>
                    <small class="min-select" v-if="product.fiyatlandirma == 'grup'">
                      {{ item.fiyat.en_az }} ile {{ item.fiyat.en_cok }} Kişiye Kadar
                    </small>
                  </div>
                </div>
                <template v-if="product.fiyatlandirma == 'tekil'">
                  <div
                    class="week-price d-flex flex-column"
                    :class="product.kampanya_k_no && 'text-danger'"
                    :style="product.kampanya_k_no && 'text-decoration: line-through'"
                  >
                    <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      <h4>{{ item.fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Gecelik</p>
                    </template>
                    <template v-else>
                      <h4>{{ item.fiyat.yetiskin | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Günlük</p>
                    </template>
                  </div>
                  <div class="week-price d-flex flex-column text-success" v-if="product.kampanya_k_no">
                    <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      <h4>{{ kampanyaHesapla(item.fiyat.yetiskin) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Gecelik</p>
                    </template>
                    <template v-else>
                      <h4>{{ kampanyaHesapla(item.fiyat.yetiskin) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Günlük</p>
                    </template>
                  </div>
                </template>
                <template v-if="product.fiyatlandirma == 'grup'">
                  <div
                    class="week-price d-flex flex-column"
                    :class="product.kampanya_k_no && 'text-danger'"
                    :style="product.kampanya_k_no && 'text-decoration: line-through'"
                  >
                    <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      <h4>{{ item.fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Gecelik</p>
                    </template>
                    <template v-else>
                      <h4>{{ item.fiyat.fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Grup</p>
                    </template>
                  </div>
                  <div class="week-price d-flex flex-column text-success" v-if="product.kampanya_k_no">
                    <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      <h4>{{ kampanyaHesapla(item.fiyat.yetiskin) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Gecelik</p>
                    </template>
                    <template v-else>
                      <h4>{{ kampanyaHesapla(item.fiyat.fiyat) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h4>
                      <p>Grup</p>
                    </template>
                  </div>
                  <div class="week-price d-flex flex-column text-warning">
                    <template v-if="product.urun_tipi == 'villa' && product.urun_tipi == 'yat'">
                      <h6>{{ kampanyaHesapla(item.fiyat.arti_kisi) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h6>
                      <p>+ Her 1 Kişi</p>
                    </template>
                    <template v-else>
                      <h6>{{ kampanyaHesapla(item.fiyat.arti_kisi) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}</h6>
                      <p>+ Her 1 Kişi</p>
                    </template>
                  </div>
                </template>
              </template>
            </li>
          </ul>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { ref, defineComponent, toRefs, computed } from '@vue/composition-api';

export default defineComponent({
  components: { flatPickr },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const expo = {};
    const { product } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);
    const ozellik = computed(() => store.getters.getUrunOzelAlanlar);
    const tumBolgeler = computed(() => store.getters.getTumBolgeler.filter((x) => x.statu == true));

    expo.date = ref(null);
    expo.config = ref({
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: true,
      showMonths: 3,
      // mode: product.value.urun_tipi == 'villa' ? 'range' : 'single',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      wrap: true,
      altInput: true,
      disable: [],
      locale: Turkish,
    });

    expo.listenToOnDayCreateEvent = (dObj, dStr, fp, dayElem) => {
      if (product.value.urun_tipi == 'tur') {
        fp.config.disable.push((dObj) => {
          if (dObj.getDay() != undefined && dObj.getMonth() != undefined) {
            //1 pazartesi 2 salı 3 carsamba 4 persembe 5 cuma 6 cumartesi 0 pazar
            let oca, sub, mar, nis, may, haz, tem, agu, eyl, eki, kas, ara;
            if (product.value.aktif_aylar.indexOf('ocak') > -1) oca = 0;
            if (product.value.aktif_aylar.indexOf('subat') > -1) sub = 1;
            if (product.value.aktif_aylar.indexOf('mart') > -1) mar = 2;
            if (product.value.aktif_aylar.indexOf('nisan') > -1) nis = 3;
            if (product.value.aktif_aylar.indexOf('mayis') > -1) may = 4;
            if (product.value.aktif_aylar.indexOf('haziran') > -1) haz = 5;
            if (product.value.aktif_aylar.indexOf('temmuz') > -1) tem = 6;
            if (product.value.aktif_aylar.indexOf('agustos') > -1) agu = 7;
            if (product.value.aktif_aylar.indexOf('eylul') > -1) eyl = 8;
            if (product.value.aktif_aylar.indexOf('ekim') > -1) eki = 9;
            if (product.value.aktif_aylar.indexOf('kasim') > -1) kas = 10;
            if (product.value.aktif_aylar.indexOf('aralik') > -1) ara = 11;

            //Günler
            let pzt, sal, car, per, cum, cumrts, paz;
            if (product.value.aktif_gunler.indexOf('pazar') >= 0) paz = 0;
            if (product.value.aktif_gunler.indexOf('pazartesi') >= 0) pzt = 1;
            if (product.value.aktif_gunler.indexOf('sali') >= 0) sal = 2;
            if (product.value.aktif_gunler.indexOf('carsamba') >= 0) car = 3;
            if (product.value.aktif_gunler.indexOf('persembe') >= 0) per = 4;
            if (product.value.aktif_gunler.indexOf('cuma') >= 0) cum = 5;
            if (product.value.aktif_gunler.indexOf('cumartesi') >= 0) cumrts = 6;

            return (
              (dObj.getMonth() != oca &&
                dObj.getMonth() != sub &&
                dObj.getMonth() != mar &&
                dObj.getMonth() != nis &&
                dObj.getMonth() != may &&
                dObj.getMonth() != haz &&
                dObj.getMonth() != tem &&
                dObj.getMonth() != agu &&
                dObj.getMonth() != eyl &&
                dObj.getMonth() != eki &&
                dObj.getMonth() != kas &&
                dObj.getMonth() != ara) ||
              (dObj.getDay() != paz &&
                dObj.getDay() != pzt &&
                dObj.getDay() != sal &&
                dObj.getDay() != car &&
                dObj.getDay() != per &&
                dObj.getDay() != cum &&
                dObj.getDay() != cumrts)
            );
          }
        });
      }
    };

    expo.bolgeTitle = computed(() => {
      return (val) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == val);

        if (bolge != undefined) {
          return bolge.baslik;
        } else {
          return '';
        }
      };
    });

    expo.kampanyaHesapla = computed(() => {
      return (val) => {
        const kampanya = product.value.kampanya;
        if (val && kampanya) {
          if (kampanya.indirim_turu == 'fiyat') {
            return val - kampanya.indirim_tutari;
          } else {
            return val - (val / 100) * kampanya.indirim_tutari;
          }
        }
      };
    });

    expo.kampanyaHesapla = computed(() => {
      return (val) => {
        const kampanya = product.value.kampanya;
        if (val && kampanya) {
          if (kampanya.indirim_turu == 'fiyat') {
            return val - kampanya.indirim_tutari;
          } else {
            return val - (val / 100) * kampanya.indirim_tutari;
          }
        }
      };
    });

    expo.ozelAlanBaslik = computed(() => {
      return (val) => {
        const ozel = ozellik.value.find((x) => x.k_no == val);
        if (ozel != undefined) return ozel.icerik[expo.defaultDil.value].baslik;
      };
    });

    expo.ozelAlanSubBaslik = computed(() => {
      return (index, val) => {
        const ozel = ozellik.value.find((x) => x.k_no == index);
        if (ozel != undefined) {
          const values = ozel.ozellik.find((x) => x.k_no == val);

          return values;
        }
      };
    });

    expo.handlerOzellikler = computed(() => {
      return (val) => {
        const ozellik = [];

        val.map((el) => {
          ozellik.push(el);
        });

        return ozellik;
      };
    });

    return { ...expo };
  },
});
</script>

<style lang="scss">
@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 57% !important;
    width: 57% !important;
  }
}

.product-price-list {
  li {
    display: flex;
    justify-content: space-between;
    border: 1px solid#6e6b7b;
    margin: 10px 0px;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    .day-price {
      text-align: right;
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 12px;
      }
    }

    .week-price {
      min-width: 150px;
      text-align: right;
      color: #0080ff;
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 12px;
      }
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      border-color: #0080ff;
      box-shadow: 0 10px 30px 2px rgba(0, 0, 0, 0.2);
    }
  }
}

.ozel-card {
  width: 100%;
  min-height: 40px;
  border: 1px solid #eee;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ozel-card-title {
    span {
      font-size: 12px;
    }
  }
  .ozel-card-value {
    font-size: 14px;
  }
}
</style>
