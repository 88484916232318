<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">
        <v-select
          v-model="filter.transfer.tipi"
          :options="transferTipi"
          :reduce="(durum) => durum.value"
          label="title"
          :clearable="false"
          placeholder="Transfer Tipi"
          class="invoice-filter-select d-block select-size-lg"
        />
      </b-col>
      <b-col cols="12" md="4">
        <vue-autosuggest
          :suggestions="neredenLokasyon"
          :limit="10"
          :input-props="{
            id: 'autosuggest__input',
            class: 'form-control form-control-lg rounded-0 bg-white',
            placeholder: 'Nereden',
          }"
          v-model="nereden_text"
          :get-suggestion-value="getSuggestionValue"
          @selected="handlerNeredenSelected"
          @input="onInputNeredenChange($event)"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">
              <i :class="`fad fa-${suggestion.item.icon}`" v-if="suggestion.item.icon"></i>
              {{ suggestion.item.icerik[defaultDil].baslik }}
            </span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="12" md="4">
        <vue-autosuggest
          :suggestions="nereyeLokasyon"
          :input-props="{
            id: 'autosuggest__input',
            class: 'form-control form-control-lg rounded-0 bg-white',
            placeholder: 'Nereye',
          }"
          v-model="nereye_text"
          :get-suggestion-value="getSuggestionValue"
          @selected="handlerNereyeSelected"
          @input="onInputNereyeChange($event)"
        >
          <template slot-scope="{ suggestion }">
            <span class="my-suggestion-item">
              <i :class="`fad fa-${suggestion.item.icon}`"></i>
              {{ suggestion.item.icerik[defaultDil].baslik }}
            </span>
          </template>
        </vue-autosuggest>
      </b-col>
      <b-col cols="12" md="4">
        <flat-pickr
          class="form-control form-control-lg rounded-0 bg-white"
          v-model="filter.transfer.a_tarih"
          :config="aConfigDate"
          placeholder="Alış Tarih Seç"
          @on-change="handlerAlisTarihChange"
        />
      </b-col>
      <b-col cols="12" md="4">
        <flat-pickr
          class="form-control form-control-lg rounded-0"
          :class="filter.transfer.tipi == 'tek_yon' ? 'bg-light' : 'bg-white'"
          v-model="filter.transfer.d_tarih"
          :config="dConfigDate"
          placeholder="Gidiş Tarih Seç"
          :disabled="filter.transfer.tipi == 'tek_yon'"
        />
      </b-col>
      <b-col cols="12" md="4">
        <RezervasyonSelectPax :form="filter.transfer" class="p-0 m-0" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-button type="submit" block variant="info" size="lg" class="rounded-0">
          <i class="fad fa-filter"></i> FİLTRE UYGULA
        </b-button>
      </b-col>
      <b-col cols="12" md="6">
        <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
          <i class="fad fa-window-close"></i> TEMİZLE
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { VueAutosuggest } from 'vue-autosuggest';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import RezervasyonSelectPax from '../ekle/RezervasyonSelectPax.vue';

import { ref, computed, defineComponent, onMounted, toRefs } from '@vue/composition-api';

export default defineComponent({
  components: { vSelect, Treeselect, flatPickr, VueAutosuggest, RezervasyonSelectPax },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};

    const { filter } = toRefs(props);

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.bolgeler = computed(() => store.getters.getTransferTarifeleri.bolgeler.filter((x) => x.bolge_k_no != null));
    expo.hoteller = computed(() => store.getters.getTransferTarifeleri.hoteller);
    expo.tarifeBolgeler = computed(() => store.getters.getTransferTarifeleri.tarifeBolgeler);
    expo.ayarlar = computed(() => store.getters.getTransferTarifeleri.ayarlar);

    expo.nereyeLokasyonSabit = ref([]);
    expo.tumLokasyonlar = ref([]);
    expo.neredenLokasyon = ref([]);
    expo.nereyeLokasyon = ref([]);

    expo.aConfigDate = ref({
      inline: false,
      showMonths: 1,
      mode: 'single',
      minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      locale: Turkish,
      disableMobile: true,
    });

    expo.dConfigDate = ref({
      inline: false,
      showMonths: 1,
      mode: 'single',
      minDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      locale: Turkish,
      disableMobile: true,
    });

    expo.form = ref({
      tipi: 'tek_yon',
      nereden: null,
      nereye: null,
      nereden_text: null,
      nereye_text: null,
      a_tarih: null,
      d_tarih: null,
      a_saat_hesapla: false,
      d_saat_hesapla: false,
      pax: {
        yetiskin: 1,
        cocuk: 0,
        bebek: 0,
      },
    });

    expo.transferTipi = ref([
      { value: 'tek_yon', title: 'Tek Yön' },
      { value: 'gelis_gidis', title: 'Geliş Gidiş' },
    ]);

    expo.nereden_text = ref(null);
    expo.nereye_text = ref(null);

    expo.handlerAlisTarihChange = (event) => {
      expo.dConfigDate.value.minDate = moment(event[0]).add(1, 'days').format('YYYY-MM-DD');
    };

    expo.handlerNeredenSelected = (item) => {
      if (item) {
        expo.nereyeLokasyon.value = [];
        const secilenBolge = item.item;

        let baslik = {};

        Object.keys(secilenBolge.icerik).map((el) => {
          baslik[el] = secilenBolge.icerik[el].baslik;
        });

        filter.value.transfer.nereden_type = secilenBolge.type;
        filter.value.transfer.nereden_text = baslik;
        expo.nereden_text.value = baslik[expo.defaultDil.value];

        const tarifeler = store.getters.getTransferTarifeleri.tarifeler;
        const secilenTarife = tarifeler.find((x) => x.k_no == secilenBolge.k_no);

        if (secilenTarife != undefined) {
          //Secilen Bolge Alt Tarifeleri varsa
          let tarifeBolge = secilenTarife.bolgeler; //aktif pasif olduguna bakmak için,

          if (secilenBolge.k_no) {
            let filteredData = secilenBolge.aktif_bolgeler;

            let filteredNewData = [];
            filteredData = filteredData.filter((row) => {
              const bolge = tarifeBolge.find((x) => x.k_no == row.k_no);

              if (row.check && bolge.statu) {
                const b = expo.tumLokasyonlar.value.find((x) => x.k_no == row.k_no);

                return b.icerik[expo.defaultDil.value].baslik;
              }
            });

            filteredData.forEach((el) => {
              if (typeof el == 'object') {
                const bolge = expo.tumLokasyonlar.value.find((x) => x.k_no == el.k_no);
                if (bolge != undefined) filteredNewData.push(bolge);
              }
            });

            filteredNewData = filteredNewData.concat(expo.hoteller.value);

            expo.nereyeLokasyonSabit.value = filteredNewData;
            expo.nereyeLokasyon.value = [
              {
                data: filteredNewData,
              },
            ];
          }
        } else {
          let k_no = secilenBolge.type == 'local' ? secilenBolge.k_no : secilenBolge.bolge_k_no;

          //Secilen Bolge Alt Tarifeleri yoksa
          const filteredData = [];
          tarifeler.forEach((tarife) => {
            const hangiTarifelerdeVar = tarife.bolgeler.find((x) => x.k_no == k_no);

            if (hangiTarifelerdeVar != undefined && hangiTarifelerdeVar.statu) {
              const tarif = expo.tumLokasyonlar.value.find((x) => x.k_no == tarife.k_no);

              if (tarif != undefined) {
                filteredData.push(tarif);
              }
            }
          });

          expo.nereyeLokasyonSabit.value = filteredData;
          expo.nereyeLokasyon.value = [
            {
              data: filteredData,
            },
          ];
        }

        secilenBolge.aktif_bolgeler == undefined
          ? (filter.value.transfer.a_saat_hesapla = true)
          : (filter.value.transfer.a_saat_hesapla = false);

        filter.value.transfer.nereden_type = secilenBolge.type;
        filter.value.transfer.nereden = secilenBolge.k_no;
      }
    };

    expo.handlerNereyeSelected = (item) => {
      const secilenBolge = item.item;

      let baslik = {};

      Object.keys(secilenBolge.icerik).map((el) => {
        baslik[el] = secilenBolge.icerik[el].baslik;
      });

      secilenBolge.aktif_bolgeler == undefined
        ? (filter.value.transfer.d_saat_hesapla = true)
        : (filter.value.transfer.d_saat_hesapla = false);

      filter.value.transfer.nereye = secilenBolge.k_no;
      filter.value.transfer.nereye_type = secilenBolge.type;
      filter.value.transfer.nereye_text = baslik;
    };

    expo.onInputNeredenChange = (text) => {
      if (text == '' || text == undefined) {
        return;
      }

      const search = text.toLocaleLowerCase(expo.defaultDil.value).trim();
      let filteredData = expo.tumLokasyonlar.value;
      filteredData = filteredData.filter((row) => {
        if (row.icerik[expo.defaultDil.value].baslik != null || row.icerik[expo.defaultDil.value].baslik != undefined) {
          return row.icerik[expo.defaultDil.value].baslik.toLocaleLowerCase(expo.defaultDil.value).includes(search);
        }
      });

      expo.neredenLokasyon.value = [
        {
          data: filteredData,
        },
      ];
    };

    expo.onInputNereyeChange = (text) => {
      if (text === '' || text === undefined) {
        return;
      }

      expo.nereyeLokasyon.value = [];

      const search = text.toLocaleLowerCase(expo.defaultDil.value).trim();
      let filteredData = expo.nereyeLokasyonSabit.value;

      filteredData = filteredData.filter((row) => {
        if (row.icerik[expo.defaultDil.value].baslik != null || row.icerik[expo.defaultDil.value].baslik != undefined) {
          return row.icerik[expo.defaultDil.value].baslik.toLocaleLowerCase(expo.defaultDil.value).includes(search);
        }
      });

      expo.nereyeLokasyon.value = [
        {
          data: filteredData,
        },
      ];
    };

    expo.getSuggestionValue = (suggestion) => {
      return suggestion.item.icerik[expo.defaultDil.value].baslik;
    };

    expo.handlerTarihChange = (event, tipi) => {
      if (event != null) {
        if (tipi == 'alis') {
          filter.value.transfer.a_tarih = moment(String(event[0])).format('YYYY-MM-DD');
        } else {
          filter.value.transfer.d_tarih = moment(String(event[0])).format('YYYY-MM-DD');
        }
      }
    };

    onMounted(() => {
      expo.tumLokasyonlar.value = expo.tarifeBolgeler.value.concat(expo.bolgeler.value, expo.hoteller.value);

      let filteredData = expo.tumLokasyonlar.value;

      filteredData = filteredData.filter((row) => {
        return row.icerik[expo.defaultDil.value].baslik;
      });

      expo.neredenLokasyon.value = [
        {
          data: filteredData,
        },
      ];
    });

    return { ...expo };
  },
});
</script>

<style lang="scss">
.autosuggest-autosuggest__results {
  .form-input {
    border: none !important;
    width: 100%;
  }
}
</style>
