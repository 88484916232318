import { render, staticRenderFns } from "./RezervasyonSelectPax.vue?vue&type=template&id=5870a757&scoped=true&"
import script from "./RezervasyonSelectPax.vue?vue&type=script&lang=js&"
export * from "./RezervasyonSelectPax.vue?vue&type=script&lang=js&"
import style0 from "./RezervasyonSelectPax.vue?vue&type=style&index=0&id=5870a757&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5870a757",
  null
  
)

export default component.exports