<template>
  <div class="product-select-pax">
    <b-form-input
      size="lg"
      class="form-control rounded-0 border select-person-input"
      v-model="inputMessage"
      placeholder="Kişi Sayısı"
      readonly
      @click="open = !open"
    />

    <div class="select-person-overlay" @click="open = !open" :class="{ active: open }"></div>
    <div class="select-person-body" :class="{ active: open }">
      <div class="d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex flex-column">
          <span class="person-title">Yetişkin</span>
          <span class="person-desc">18-99 Yaş Arası</span>
        </div>
        <div class="w-50">
          <b-form-group>
            <b-input-group>
              <template #prepend>
                <b-button squared variant="danger" @click="handlerPaxChange('yetiskin', -1)"> - </b-button>
              </template>
              <b-form-input class="text-center rounded-0" v-model="form.pax.yetiskin"></b-form-input>
              <template #append>
                <b-button squared variant="success" @click="handlerPaxChange('yetiskin', 1)"> + </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex flex-column">
          <span class="person-title">Çocuk</span>
          <span class="person-desc">7-17 Yaş Arası</span>
        </div>
        <div class="w-50">
          <b-form-group>
            <b-input-group>
              <template #prepend>
                <b-button squared variant="danger" @click="handlerPaxChange('cocuk', -1)"> - </b-button>
              </template>
              <b-form-input class="text-center rounded-0" v-model="form.pax.cocuk"></b-form-input>
              <template #append>
                <b-button squared variant="success" @click="handlerPaxChange('cocuk', 1)"> + </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center pb-2">
        <div class="d-flex flex-column">
          <span class="person-title">Bebek</span>
          <span class="person-desc">0-7 Yaş Arası</span>
        </div>
        <div class="w-50">
          <b-form-group>
            <b-input-group>
              <template #prepend>
                <b-button squared variant="danger" @click="handlerPaxChange('bebek', -1)"> - </b-button>
              </template>
              <b-form-input class="text-center rounded-0" v-model="form.pax.bebek"></b-form-input>
              <template #append>
                <b-button squared variant="success" @click="handlerPaxChange('bebek', 1)"> + </b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, toRefs } from '@vue/composition-api';

export default defineComponent({
  props: {
    form: {
      type: Object,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.inputMessage = ref(null);
    const { form } = toRefs(props);
    expo.open = ref(false);
    const inputMessages = () => {
      if (form.value.pax.yetiskin > 0) {
        expo.inputMessage.value = `${form.value.pax.yetiskin} Yetişkin`;
      }
      if (form.value.pax.cocuk > 0) {
        expo.inputMessage.value += `, ${form.value.pax.cocuk} Çocuk`;
      }
      if (form.value.pax.bebek > 0) {
        expo.inputMessage.value += `, ${form.value.pax.bebek} Bebek`;
      }
    };

    expo.handlerPaxChange = (type, event) => {
      if (type == 'yetiskin') {
        form.value.pax.yetiskin += event;
        if (form.value.pax.yetiskin <= 0) {
          form.value.pax.yetiskin = 1;
        }
      } else if (type == 'cocuk') {
        form.value.pax.cocuk += event;
        if (form.value.pax.cocuk < 0) {
          form.value.pax.cocuk = 0;
        }
      } else {
        form.value.pax.bebek += event;
        if (form.value.pax.bebek < 0) {
          form.value.pax.bebek = 0;
        }
      }

      inputMessages();
    };

    onMounted(() => {
      // const selectPersonInput = document.querySelector('.select-person-input');
      // const selectPersonBody = document.querySelector('.select-person-body');
      // const selectPersonOverlay = document.querySelector('.select-person-overlay');
      // function collapseSelectPerson() {
      //   const active = selectPersonBody.classList.contains('active');
      //   if (active) {
      //     selectPersonBody.classList.remove('active');
      //     selectPersonOverlay.classList.remove('active');
      //   } else {
      //     selectPersonBody.classList.add('active');
      //     selectPersonOverlay.classList.add('active');
      //   }
      // }
      // if (selectPersonInput) {
      //   selectPersonInput.addEventListener('click', (e) => {
      //     collapseSelectPerson();
      //   });
      // }
      // if (selectPersonOverlay) {
      //   selectPersonOverlay.addEventListener('click', (e) => {
      //     collapseSelectPerson();
      //   });
      // }
      inputMessages();
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.product-select-pax {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 10px 0px;
  .select-person-input {
    height: 42px;
    display: block;
    z-index: 10;
    background: #fff;
    cursor: pointer;
  }

  .select-person-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.025);
    opacity: 1;
    transition: opacity 0.4s;
    visibility: hidden;
    z-index: -1;
    &.active {
      visibility: visible;
    }
  }

  .select-person-body {
    display: none;
    padding: 10px 10px;
    margin-top: 5px;
    position: absolute;
    overflow: hidden;
    border: 1px solid #eee;
    // box-shadow: rgb(213, 213, 213) 5px 5px 5px;
    width: 100%;
    background-color: white;
    z-index: 2;
    transition: all 0.4s ease-in-out;
    .person-title {
      font-size: 14px;
      font-weight: 600;
    }
    .person-desc {
      font-size: 10px;
      color: #b0b0b0;
    }
    &.active {
      transition: all 4s ease-in-out;
      display: block;
    }
  }
}
</style>
