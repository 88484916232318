<template>
  <div class="product-card">
    <div class="product-card__header">
      <b-img :src="product.gorseller | productImage(product.k_no)" width="100" @click="showImg(0)" />
      <div class="product-img-zoom" @click="showImg(0)">
        <i class="fad fa-expand-arrows"></i>
      </div>
      <div class="product-discount" v-if="product.kampanya_k_no">{{ handlerKampanyaOrani(product.kampanya) }}</div>
      <div class="product-villa-type" v-if="product.urun_tipi == 'villa' || product.urun_tipi == 'yat'">
        <div class="product-villa-type__item">
          <i class="fad fa-bed fa-2x"></i>
          {{ product.villa_ozellik.oda }} Oda
        </div>
        <div class="product-villa-type__item">
          <i class="fad fa-users fa-2x"></i>
          {{ product.villa_ozellik.kisi }} Kişi
        </div>
        <div class="product-villa-type__item">
          <i class="fad fa-bath fa-2x"></i>
          {{ product.villa_ozellik.banyo }} Banyo
        </div>
      </div>
    </div>
    <div class="product-card__body">
      <div class="product-card__body__title" v-b-tooltip.hover.top="product.icerik[defaultDil].baslik">
        {{ product.icerik[defaultDil].baslik }}
      </div>
      <div class="product-card-price">
        <template v-if="product.satis_durum">
          <template v-if="product.fiyat_turu == 'donemsel'">
            <template v-if="product.bugun_satis_fiyat.length">
              <template v-if="product.urun_tipi == 'villa' || product.urun_tipi == 'yat' || product.urun_tipi == 'arac_kira'">
                <del class="product-card-price__old" v-if="product.kampanya_k_no">
                  {{ product.bugun_satis_fiyat[0].fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                </del>
                <ins class="product-card-price__new">
                  {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat) | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                </ins>
              </template>
              <template v-else>
                <del class="product-card-price__old" v-if="product.kampanya_k_no">
                  {{ product.bugun_satis_fiyat[0].fiyat.yetiskin | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                </del>
                <ins class="product-card-price__new">
                  {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.yetiskin) | exc(product.kur_turu) }}
                  {{ exchange.banka_kodu }}
                </ins>
              </template>
            </template>
            <template v-else>
              <b-badge class="rounded-0 p-2 h5" variant="danger"> Satışa Kapalı </b-badge>
            </template>
          </template>
          <template v-if="product.fiyat_turu == 'bolgesel'">
            <template v-if="bolgeSecildimi && product.bugun_satis_fiyat.length">
              <template v-if="product.fiyatlandirma == 'tekil'">
                <template v-if="product.urun_tipi == 'villa' || product.urun_tipi == 'yat' || product.urun_tipi == 'arac_kira'">
                  <del class="product-card-price__old" v-if="product.kampanya_k_no">
                    {{ product.bugun_satis_fiyat[0].fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                  </del>
                  <ins class="product-card-price__new">
                    {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat) | exc(product.kur_turu) }}
                    {{ exchange.banka_kodu }}
                  </ins>
                </template>
                <template v-else>
                  <del class="product-card-price__old" v-if="product.kampanya_k_no">
                    {{ product.bugun_satis_fiyat[0].fiyat.yetiskin | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                  </del>
                  <ins class="product-card-price__new">
                    {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.yetiskin) | exc(product.kur_turu) }}
                    {{ exchange.banka_kodu }}
                  </ins>
                </template>
              </template>
              <template v-else>
                <div
                  class="d-flex"
                  v-b-tooltip.hover.top="
                    `${product.bugun_satis_fiyat[0].fiyat.en_az} ile ${
                      product.bugun_satis_fiyat[0].fiyat.en_cok
                    } Kişiye Kadar. + Her Kişi için ${artiKisiFiyat(product.bugun_satis_fiyat[0].fiyat.arti_kisi)} ${
                      exchange.banka_kodu
                    } `
                  "
                >
                  <template v-if="product.urun_tipi == 'villa' || product.urun_tipi == 'yat' || product.urun_tipi == 'arac_kira'">
                    <del class="product-card-price__old" v-if="product.kampanya_k_no">
                      {{ product.bugun_satis_fiyat[0].fiyat | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                    </del>
                    <ins class="product-card-price__new">
                      {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat) | exc(product.kur_turu) }}
                      {{ exchange.banka_kodu }}
                    </ins>
                  </template>
                  <template v-else>
                    <del class="product-card-price__old" v-if="product.kampanya_k_no">
                      {{ product.bugun_satis_fiyat[0].fiyat.yetiskin | exc(product.kur_turu) }} {{ exchange.banka_kodu }}
                    </del>
                    <ins class="product-card-price__new">
                      {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.yetiskin) | exc(product.kur_turu) }}
                      {{ exchange.banka_kodu }}
                    </ins>
                  </template>
                </div>
              </template>
            </template>
            <template v-else-if="bolgeSecildimi && !product.bugun_satis_fiyat.length">
              <b-badge class="rounded-0 p-2 h5" variant="warning"> Seçilen Bölgeye Fiyat Tanımlanmamış </b-badge>
            </template>
            <template v-else>
              <b-badge class="rounded-0 p-2 h5" variant="warning"> Bölge Seçimi Yapınız </b-badge>
            </template>
          </template>
        </template>
        <template v-else>
          <b-badge class="rounded-0 p-2 h5" variant="danger"> Satışa Kapalı </b-badge>
        </template>
      </div>
    </div>
    <div class="product-card__footer">
      <b-button-group class="w-100">
        <b-button
          squared
          class="mr-1"
          size="sm"
          href="#"
          variant="outline-primary"
          v-if="product.satis_yontemi == 'sorgula'"
          :disabled="!product.bugun_satis_fiyat.length"
          @click="openModal = true"
        >
          <i class="fad fa-bell-on"></i>
          Rezervasyon Yap
        </b-button>
        <b-button
          squared
          class="mr-1"
          size="sm"
          href="#"
          variant="outline-info"
          :disabled="!product.bugun_satis_fiyat.length"
          v-b-tooltip.hover.top="'Sepete Ekle'"
          v-else
          @click="openModal = true"
        >
          <i class="fad fa-cart-plus"></i> Sepete Ekle
        </b-button>
        <b-button
          squared
          size="sm"
          href="#"
          variant="outline-warning"
          v-b-tooltip.hover.top="'Detay İncele'"
          @click="openDetayModal = true"
        >
          <i class="fad fa-info"></i>
        </b-button>
      </b-button-group>
    </div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="lightBoxMedia" :index="indexRef" @hide="onHide" />
    <UrunSepetModal :product="product" :openModal="openModal" @closeModal="openModal = $event" />
    <UrunDetayModal :product="product" :openModal="openDetayModal" @closeModal="openDetayModal = $event" />
  </div>
</template>

<script>
import store from '@/store';
import VueEasyLightbox from 'vue-easy-lightbox';
import { ref, defineComponent, computed, toRefs, watch } from '@vue/composition-api';
import UrunDetayModal from './UrunDetayModal.vue';
import UrunSepetModal from './UrunSepetModal.vue';
import { exChange } from '@/libs/global';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: { VueEasyLightbox, UrunSepetModal, UrunDetayModal },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { product } = toRefs(props);
    const { route } = useRouter();
    expo.openModal = ref(false);
    expo.openDetayModal = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);

    expo.bolgeSecildimi = ref(false);

    expo.visibleRef = ref(false);
    expo.indexRef = ref(0);
    expo.activeIndex = ref(0);

    expo.showImg = (index) => {
      expo.indexRef.value = index;
      expo.visibleRef.value = true;
    };

    expo.onHide = () => (expo.visibleRef.value = false);

    expo.lightBoxMedia = computed(() => {
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      const db = store.getters.getUserFirma.db;
      return product.value.gorseller.reduce((acc, cur) => {
        return [
          ...acc,
          {
            src: `${base_url}uploads/${db}/urun/${product.value.k_no}/${cur.gorsel}`,
          },
        ];
      }, []);
    });

    expo.handlerKampanyaOrani = computed(() => {
      return (val) => {
        return val.indirim_turu == 'yuzde'
          ? `${val.indirim_tutari} %`
          : `${exChange(val.indirim_tutari, product.value.kur_turu).toFixed(2)} ${expo.exchange.value.banka_kodu}`;
      };
    });

    expo.kampanyaFiyatHesapla = computed(() => {
      return (price) => {
        const kampanya = product.value.kampanya;
        let fiyat = 0;
        if (kampanya) {
          if (kampanya.indirim_turu == 'yuzde') {
            const indirim = (price / 100) * kampanya.indirim_tutari;
            fiyat = price - indirim;
          } else {
            fiyat = price - kampanya.indirim_tutari;
          }
        } else {
          fiyat = price;
        }

        return fiyat;
      };
    });

    expo.artiKisiFiyat = computed(() => {
      return (val) => {
        return exChange(val, product.value.kur_turu).toFixed(2);
      };
    });

    watch(
      route,
      (val) => {
        if (val.query.bolge_k_no != undefined) expo.bolgeSecildimi.value = true;
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.product-card {
  position: relative;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: 1px solid #ddd;
  margin: 10px 5px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &__header {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-height: 209px;
      cursor: pointer;
    }
    .product-img-zoom {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: #ffffff;
      font-size: 26px;
      cursor: pointer;
    }
    .product-villa-type {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      background: linear-gradient(299deg, rgba(96, 96, 96, 1) 0%, rgba(255, 255, 255, 0.041075805322128844) 100%);
      width: 80px;
      height: 100%;
      text-align: center;
      padding-top: 10px;
      &__item {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
      }
    }
    .product-discount {
      position: absolute;
      top: 10px;
      left: 0px;
      background: linear-gradient(299deg, rgba(255, 0, 0, 0.055081407563025264) 0%, rgba(255, 0, 0, 1) 100%);
      color: #fff;
      padding: 10px;
    }
  }
  &__body {
    position: relative;
    width: 100%;
    &__title {
      font-family: inherit;
      font-size: 18px;
      line-height: 2;
      text-align: center;
      font-weight: 500;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px 10px;
      color: #1876d2;
    }
    .product-card-price {
      display: flex;
      justify-content: center;
      padding-bottom: 10px;
      &__old {
        display: block;
        font-family: inherit;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: red;
        padding: 0px 5px;
        text-decoration: line-through;
      }
      &__old {
        display: block;
        font-family: inherit;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: red;
        padding: 0px 5px;
        text-decoration: line-through;
      }
      &__new {
        display: block;
        font-family: inherit;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        color: #0fa801;
        padding: 0px 5px;
      }
    }
  }
  &__footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(143, 143, 143, 0.58);
    -moz-box-shadow: 0px 5px 15px 0px rgba(143, 143, 143, 0.58);
    box-shadow: 0px 5px 15px 0px rgba(143, 143, 143, 0.58);
  }
}
</style>
