<template>
  <div>
    <b-modal
      lazy
      :visible="openModal"
      @change="(val) => $emit('closeModal', false)"
      class="rounded-0"
      centered
      title="Sepete Ekle"
      hide-footer
    >
      <UrunSepetYatVilla v-if="product.urun_tipi == 'villa' || product.urun_tipi == 'yat'" :product="product" />
      <UrunSepetTur v-if="product.urun_tipi == 'tur'" :product="product" />
      <UrunSepetAracKira v-if="product.urun_tipi == 'arac_kira'" :product="product" />
    </b-modal>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import UrunSepetTur from './UrunSepetTur.vue';
import UrunSepetAracKira from './UrunSepetAracKira.vue';
import UrunSepetYatVilla from './UrunSepetYatVilla.vue';
export default defineComponent({
  components: {
    UrunSepetTur,
    UrunSepetAracKira,
    UrunSepetYatVilla,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
