<template>
  <div>
    <b-row>
      <b-col cols="12" md="3">
        <v-select
          v-model="filter.urun.urun_tipi"
          :options="urunTipi"
          :reduce="(durum) => durum.value"
          label="title"
          :clearable="false"
          placeholder="Ürün Tipi..."
          class="invoice-filter-select d-block select-size-lg"
        />
      </b-col>
      <b-col cols="12" md="3">
        <b-input size="lg" class="rounded-0" v-model="filter.urun.arama" placeholder="Ürün Ara..."></b-input>
      </b-col>
      <b-col cols="12" md="3" v-if="filter.urun.urun_tipi == 'villa' || filter.urun.urun_tipi == 'yat'">
        <v-select
          v-model="filter.urun.kisi_sayisi"
          :options="kisiSayisi"
          :reduce="(durum) => durum.value"
          label="title"
          :clearable="false"
          placeholder="Kişi Sayısı..."
          class="invoice-filter-select d-block select-size-lg"
        />
      </b-col>
      <b-col cols="12" md="3" v-if="filter.urun.urun_tipi == 'villa' || filter.urun.urun_tipi == 'yat'">
        <v-select
          v-model="filter.urun.oda_sayisi"
          :options="odaSayisi"
          :reduce="(durum) => durum.value"
          label="title"
          :clearable="false"
          placeholder="Oda Sayısı..."
          class="invoice-filter-select d-block select-size-lg"
        />
      </b-col>
      <b-col cols="12" md="3">
        <v-select
          v-model="filter.urun.satis_durum"
          :options="satisDurum"
          :reduce="(durum) => durum.value"
          label="title"
          :clearable="false"
          placeholder="Satış Durumu"
          class="invoice-filter-select d-block select-size-lg"
        />
      </b-col>
      <b-col cols="12" md="3" v-if="kategoriler.length > 0">
        <treeselect
          :options="kategoriler"
          v-model="filter.urun.kategori_k_no"
          :normalizer="normalizer"
          :flat="true"
          :sort-value-by="sortValueBy"
          :default-expand-level="1"
          noChildrenText="Alt Öge Yok."
          placeholder="Lütfen Kategori Seçiniz"
          aria-describedby="input-1-live-feedback"
          class="rounded-0"
          :multiple="true"
          :max-height="200"
        />
      </b-col>
      <b-col cols="12" md="3" v-if="bolgeler.length > 0">
        <treeselect
          :options="bolgeler"
          v-model="filter.urun.bolge_k_no"
          :normalizer="normalizer"
          :flat="true"
          :sort-value-by="sortValueBy"
          :default-expand-level="1"
          noChildrenText="Alt Öge Yok."
          :placeholder="filter.urun.urun_tipi == 'tur' ? 'Hizmet Verilen Lokasyonlar' : 'Lokasyon'"
          aria-describedby="input-1-live-feedback"
          class="rounded-0"
          :multiple="false"
          :max-height="200"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-button type="submit" block variant="info" size="lg" class="rounded-0">
          <i class="fad fa-filter"></i> FİLTRE UYGULA
        </b-button>
      </b-col>
      <b-col cols="12" md="6">
        <b-button block variant="danger" size="lg" class="rounded-0" @click="$emit('filtered', false)">
          <i class="fad fa-window-close"></i> TEMİZLE
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { defineComponent, ref, computed } from '@vue/composition-api';
export default defineComponent({
  components: { vSelect, Treeselect },
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kategoriler = computed(() => store.getters.getKategoriler.filter((x) => x.turu == 'urun'));
    expo.bolgeler = computed(() => store.getters.getBolgeler);

    expo.sortValueBy = ref('INDEX');

    expo.normalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.icerik != undefined && node.icerik[expo.defaultDil.value].baslik,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.satisDurum = ref([
      { value: true, title: 'Satışa Açık' },
      { value: false, title: 'Satışa Kapalı' },
    ]);

    expo.urunTipi = ref([
      { value: 'villa', title: 'Villalar' },
      { value: 'yat', title: 'Yatlar' },
      { value: 'tur', title: 'Turlar' },
      { value: 'arac_kira', title: 'Araçlar' },
      { value: 'emlak', title: 'Emlak Evler' },
    ]);

    expo.kisiSayisi = ref([
      { value: 1, title: '1 Kişi' },
      { value: 2, title: '2 Kişi' },
      { value: 3, title: '3 Kişi' },
      { value: 4, title: '4 Kişi' },
      { value: 5, title: '5 Kişi' },
      { value: 6, title: '6 Kişi' },
      { value: 7, title: '7 Kişi' },
      { value: 8, title: '8 Kişi' },
      { value: 9, title: '9 Kişi' },
      { value: 10, title: '10+ Kişi' },
    ]);

    expo.odaSayisi = ref([
      { value: 1, title: '1 Oda' },
      { value: 2, title: '2 Oda' },
      { value: 3, title: '3 Oda' },
      { value: 4, title: '4 Oda' },
      { value: 5, title: '5 Oda' },
      { value: 6, title: '6 Oda' },
      { value: 7, title: '7 Oda' },
      { value: 8, title: '8 Oda' },
      { value: 9, title: '9 Oda' },
      { value: 10, title: '10+ Oda' },
    ]);

    expo.handlerSelectProduct = (event) => {};

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
