<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-2 border-bottom">
      <h5 class="font-weight-bold">Yetişkin :</h5>
      <h5 class="text-primary font-weight-bold" v-if="product.bugun_satis_fiyat[0].fiyat.yetiskin > 0">
        {{ form.pax.yetiskin }} x {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.yetiskin) | exc(product.kur_turu) }}
        {{ product.kur_turu }} =
        {{
          urun_toplam(form.pax.yetiskin, kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.yetiskin))
            | exc(product.kur_turu)
        }}
        {{ product.kur_turu }}
      </h5>
      <h5 class="text-success font-weight-bold" v-else>Ücretsiz</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom" v-if="form.pax.cocuk > 0">
      <h5 class="font-weight-bold">Cocuk :</h5>
      <h5 class="text-primary font-weight-bold" v-if="product.bugun_satis_fiyat[0].fiyat.cocuk > 0">
        {{ form.pax.cocuk }} x {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.cocuk) | exc(product.kur_turu) }}
        {{ product.kur_turu }} =
        {{ urun_toplam(form.pax.cocuk, kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.cocuk)) | exc(product.kur_turu) }}
        {{ product.kur_turu }}
      </h5>
      <h5 class="text-success font-weight-bold" v-else>Ücretsiz</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom" v-if="form.pax.bebek > 0">
      <h5 class="font-weight-bold">Bebek :</h5>
      <h5 class="text-primary font-weight-bold" v-if="product.bugun_satis_fiyat[0].fiyat.bebek > 0">
        {{ form.pax.bebek }} x {{ kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.bebek) | exc(product.kur_turu) }}
        {{ product.kur_turu }} =
        {{ urun_toplam(form.pax.bebek, kampanyaFiyatHesapla(product.bugun_satis_fiyat[0].fiyat.bebek)) | exc(product.kur_turu) }}
        {{ product.kur_turu }}
      </h5>
      <h5 class="text-success font-weight-bold" v-else>Ücretsiz</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom">
      <h5 class="font-weight-bold">{{ product.kdv_turu == 'dahil' ? 'Genel Toplam' : 'Ara Toplam' }} :</h5>
      <h5 class="text-primary font-weight-bold">{{ productTotal.ara_toplam | exc(product.kur_turu) }} {{ product.kur_turu }}</h5>
    </div>
    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom" v-if="product.kdv_turu == 'haric'">
      <h5 class="font-weight-bold">
        KDV Toplam ( <b> % {{ product.kdv_oran }} </b> ) :
      </h5>
      <h5 class="text-primary font-weight-bold">{{ productTotal.kdv_toplam | exc(product.kur_turu) }} {{ product.kur_turu }}</h5>
    </div>

    <div class="d-flex justify-content-between align-items-center pt-3 pb-2 border-bottom" v-if="product.kdv_turu == 'haric'">
      <h5 class="font-weight-bold">Genel Toplam :</h5>
      <h5 class="text-primary font-weight-bold">
        {{ productTotal.genel_toplam | exc(product.kur_turu) }} {{ product.kur_turu }}
      </h5>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { ref, defineComponent, computed, onMounted, toRefs, watch } from '@vue/composition-api';
import { urun_toplam, kdv_hesapla } from '@/libs/global';
export default defineComponent({
  props: {
    form: { type: Object, required: true },
    product: { type: Object, required: true },
  },
  setup(props, context) {
    const expo = {};
    expo.urun_toplam = ref(urun_toplam);
    const { product, form } = toRefs(props);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.rezervasyon = computed(() => store.getters.getDirekRezervasyon);

    expo.productTotal = ref({
      ara_toplam: 0,
      kdv_toplam: 0,
      genel_toplam: 0,
    });

    expo.kampanyaFiyatHesapla = (price) => {
      const kampanya = product.value.kampanya;

      let fiyat = 0;
      if (kampanya) {
        if (kampanya.indirim_turu == 'yuzde') {
          const indirim = (price / 100) * kampanya.indirim_tutari;
          fiyat = price - indirim;
        } else {
          fiyat = price - kampanya.indirim_tutari;
        }
      } else {
        fiyat = price;
      }

      return fiyat;
    };

    expo.handlerHesapla = () => {
      let yetiskin = urun_toplam(
          form.value.pax.yetiskin,
          expo.kampanyaFiyatHesapla(product.value.bugun_satis_fiyat[0].fiyat.yetiskin)
        ),
        cocuk = urun_toplam(form.value.pax.cocuk, expo.kampanyaFiyatHesapla(product.value.bugun_satis_fiyat[0].fiyat.cocuk)),
        bebek = urun_toplam(form.value.pax.bebek, expo.kampanyaFiyatHesapla(product.value.bugun_satis_fiyat[0].fiyat.bebek));

      let ara_toplam = yetiskin + cocuk + bebek,
        kdv_toplam = product.value.kdv_turu == 'haric' ? kdv_hesapla(ara_toplam, product.value.kdv_oran) : 0,
        genel_toplam = ara_toplam + kdv_toplam;

      expo.productTotal.value.ara_toplam = ara_toplam;
      expo.productTotal.value.kdv_toplam = kdv_toplam;
      expo.productTotal.value.genel_toplam = genel_toplam;
    };

    watch(
      form.value,
      (val) => {
        expo.handlerHesapla();
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
