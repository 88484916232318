<template>
  <div class="products">
    <div class="overlay" :class="{ active: filtered }" @click="$emit('filtered', false)" />
    <div class="product-header" :class="{ active: filtered }">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)" class="p-2" autocomplete="off">
          <UrunFiltrele :filter="filter" @handlerFilter="handlerFiltrele" @filtered="handlerFilterTemizle($event)" />
        </b-form>
      </validation-observer>
    </div>
    <div class="product-body p-4">
      {{ bolgeTitle(filter.urun.bolge_k_no) }} Bölgesi için Toplam Ürün {{ count }}
      <b-overlay :show="loaded" variant="light" :opacity="0.8" blur="2px" rounded="sm">
        <b-row>
          <template v-if="filter.filtre_turu == 'urun' && rows.length">
            <b-col cols="12" md="3" v-for="(item, index) in rows" :key="index">
              <UrunCardItem :product="item" />
            </b-col>
          </template>
          <template v-else-if="filter.filtre_turu == 'transfer' && Object.keys(transfer).length">
            <b-col cols="12" md="6" v-for="(item, index) in transfer.araclar" :key="index">
              <TransferCardItem :product="item" :transfer="transfer" />
            </b-col>
          </template>
        </b-row>
        <b-row v-if="filter.filtre_turu == 'urun'">
          <b-col cols="12">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </b-col>
        </b-row>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, defineComponent, toRefs, watch } from '@vue/composition-api';
import UrunFiltrele from './component/urunler/Filtrele.vue';
import UrunCardItem from './component/urunler/UrunCardItem.vue';
import TransferCardItem from './component/urunler/TransferCardItem.vue';
import Pagination from './component/listele/Pagination.vue';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    UrunFiltrele,
    UrunCardItem,
    TransferCardItem,
    Pagination,
  },
  props: {
    filtered: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { route, router } = useRouter();
    expo.loaded = ref(false);
    expo.updated = ref(false);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    const tumBolgeler = computed(() => store.getters.getTumBolgeler.filter((x) => x.statu == true));

    expo.bolgeTitle = computed(() => {
      return (val) => {
        const bolge = tumBolgeler.value.find((x) => x.k_no == val);

        if (bolge != undefined) {
          return bolge.icerik[expo.defaultDil.value].baslik;
        } else {
          return '';
        }
      };
    });

    context.emit('filtered', true);

    expo.filter = ref({
      filtre_turu: 'urun',
      urun: {
        urun_tipi: null,
        arama: null,
        kategori_k_no: [],
        bolge_k_no: null,
        kisi_sayisi: null,
        oda_sayisi: null,
        satis_durum: null,
        page: 1,
        page_size: 20,
      },
      transfer: {
        tipi: 'tek_yon',
        nereden: null,
        nereye: null,
        nereden_text: null,
        nereye_text: null,
        nereden_type: null,
        nereye_type: null,
        a_tarih: null,
        d_tarih: null,
        a_saat_hesapla: false,
        d_saat_hesapla: false,
        pax: {
          yetiskin: 1,
          cocuk: 0,
          bebek: 0,
        },
      },
    });

    expo.transfer = ref({});
    expo.rows = ref([]);
    expo.count = ref(0);

    expo.handlerFilterTemizle = (event) => {
      context.emit('filtered', event);
      router.push({
        query: { page: expo.filter.value.urun.page, page_size: expo.filter.value.urun.page_size },
      });
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerFiltrele = () => {
      let query = {};

      if (expo.filter.value.urun.urun_tipi) {
        query.urun_tipi = expo.filter.value.urun.urun_tipi;
      } else {
        delete query.urun_tipi;
      }
      if (expo.filter.value.urun.arama) {
        query.arama = expo.filter.value.urun.arama;
      } else {
        delete query.arama;
      }
      if (expo.filter.value.urun.kategori_k_no.length) {
        query.kategori_k_no = expo.filter.value.urun.kategori_k_no.toString();
      } else {
        delete query.kategori_k_no;
      }

      if (expo.filter.value.urun.bolge_k_no) {
        query.bolge_k_no = expo.filter.value.urun.bolge_k_no.toString();
      } else {
        delete query.bolge_k_no;
      }
      if (expo.filter.value.urun.kisi_sayisi) {
        query.kisi_sayisi = parseInt(expo.filter.value.urun.kisi_sayisi);
      } else {
        delete query.kisi_sayisi;
      }
      if (expo.filter.value.urun.oda_sayisi) {
        query.oda_sayisi = parseInt(expo.filter.value.urun.oda_sayisi);
      } else {
        delete query.oda_sayisi;
      }

      if (expo.filter.value.urun.satis_durum) {
        query.satis_durum = expo.filter.value.urun.satis_durum;
      } else {
        delete query.satis_durum;
      }

      router.push({
        query: { ...query },
      });
    };

    expo.handlerFetchAllData = () => {
      expo.loaded.value = true;

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      const baseUrl =
        expo.filter.value.filtre_turu == 'urun'
          ? '/rezervasyonlar/rezervasyon-urun-sorgula-post'
          : '/rezervasyonlar/rezervasyon-transfer-sorgula-post';

      const payload = expo.filter.value.filtre_turu == 'urun' ? route.value.query : expo.filter.value.transfer;

      axiosIns.post(baseUrl, payload).then((res) => {
        if (res.data.success) {
          if (expo.filter.value.filtre_turu == 'urun') {
            expo.rows.value = res.data.data;
            expo.count.value = res.data.count;
          } else {
            expo.transfer.value = res.data;
          }
          context.emit('filtered', false);
          expo.loaded.value = false;
        }
      });
    };

    watch(
      route,
      (val) => {
        if (val.hash != '#' && val.name == 'rezervasyon-urunler') {
          expo.handlerFetchAllData();
        }
        // expo.filter.value = {
        //   urun_tipi: route.value.query.urun_tipi ? route.value.query.urun_tipi : null,
        //   bolge_k_no: route.value.query.bolge_k_no ? route.value.query.bolge_k_no : null,
        //   tahsilat_durum: route.value.query.tahsilat_durum ? route.value.query.tahsilat_durum : null,
        //   rezervasyon_durum: route.value.query.rezervasyon_durum ? route.value.query.rezervasyon_durum : null,
        //   rezervasyon_kapama: route.value.query.rezervasyon_kapama ? route.value.query.rezervasyon_kapama : null,
        // };
      },
      { immediate: true }
    );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
