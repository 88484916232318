<template>
  <b-overlay :show="loaded" variant="primary">
    <b-row>
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-between">
          <RezervasyonSelectPax :form="form" />
        </div>
      </b-col>
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <flat-pickr
            class="form-control form-control-lg rounded-0 border border-primary mb-4 bg-white"
            v-model="date"
            :config="config"
            :events="config.events"
            placeholder="Rezervasyon Tarihi Seç"
            @on-change="musaitlikTakvimChange"
            @on-day-create="listenToOnDayCreateEvent"
          />
        </div>
      </b-col>

      <b-col cols="12" v-if="promosyonMusaitmi">
        <UrunRezervasyonBilgiYatVilla :form="form" />
        <b-button block squared variant="success" @click="handlerSepeteEkle(true)">
          <i class="fad fa-bell-on"></i>
          Rezervasyon Oluştur
        </b-button>
        <b-button block squared variant="outline-danger" size="sm" @click="handlerRezervasyonTemizle">
          <i class="fad fa-trash"></i>
          Temizle
        </b-button>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
import RezervasyonSelectPax from '../ekle/RezervasyonSelectPax.vue';
import UrunRezervasyonBilgiYatVilla from './UrunRezervasyonBilgiYatVilla.vue';
import { ref, defineComponent, computed, onMounted, toRefs, watch } from '@vue/composition-api';
import { registerNumber, registerTicketNumber } from '../../../../../api/helpers/variables/variables';
import store from '@/store';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: { flatPickr, RezervasyonSelectPax, UrunRezervasyonBilgiYatVilla },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { router } = useRouter();
    const toast = useToast();
    const { product } = toRefs(props);
    expo.loaded = ref(false);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.rezervasyon = computed(() => store.getters.getSorSatRezervasyon);

    expo.date = ref(null);
    expo.config = ref({
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: window.innerWidth < 600 ? 1 : 2,
      mode: 'range',
      minDate: 'today',
      dateFormat: 'd.m.Y',
      ariaDateFormat: 'd.m.Y',
      altInput: false,
      disable: [],
      enable: [],
      locale: Turkish,
    });

    const arrRezervasyonBas = ref([]);
    const arrRezervasyonBit = ref([]);
    const arrRezervasyonDol = ref([]);

    expo.form = ref({
      urun_k_no: null,
      bilet_no: null,
      urun_tipi: 'villa',
      urun_kodu: null,
      baslik: null,
      gorseller: null,
      rezervasyon_tarihi: {
        giris: null,
        cikis: null,
      },
      pax: {
        yetiskin: 1,
        cocuk: 0,
        bebek: 0,
      },
      seans_saati: null,
      bugun_satis_fiyat: null,
      kur_turu: null,
      kdv_turu: null,
      kdv_oran: 0,
      kampanya: {},
      ekstra_hizmet_sec: false,
      ekstra_hizmet_k_no: [],
      ekstra_hizmetler: [],
      depozito_tahsil_durum: false,
      depozito: null,
      gun_fiyatlar: [],
      odeme_turu: 'hepsi', // hepsi,on_odeme
      toplam_gun: 0,
      on_odeme_oran: 0,
      sabit_toplam: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        genel_toplam: 0,
      },
      kur_toplam: {
        yetiskin: 0,
        cocuk: 0,
        bebek: 0,
        toplam: 0,
        ekstra_toplam: 0,
        kupon_indirim: 0,
        kdv_toplam: 0,
        genel_toplam: 0,
      },
    });

    expo.listenToOnDayCreateEvent = (dObj, dStr, fp, dayElem) => {
      // yapılan eylemlere gore takvim düzenlemesi yapılacak
      // console.log(dObj + ' ' + dStr + ' ' + fp);
      if (arrRezervasyonDol.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bas-bit-gun');
      }
      if (arrRezervasyonBas.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bas-gun');
      }
      if (arrRezervasyonBit.value.indexOf(dayElem.ariaLabel) !== -1) {
        dayElem.classList.add('bit-gun');
      }
    };

    expo.musaitlikTakvimChange = (event) => {
      if (event[0] && event[1] != null) {
        expo.form.value.rezervasyon_tarihi.giris = moment(String(event[0])).format('YYYY-MM-DD');
        expo.form.value.rezervasyon_tarihi.cikis = moment(String(event[1])).format('YYYY-MM-DD');

        if (expo.form.value.rezervasyon_tarihi.giris != null && expo.form.value.rezervasyon_tarihi.giris) {
          expo.handlerRezervasyonSorgula();
        }
      }
    };

    expo.promosyonMusaitmi = computed(() => {
      if (
        expo.form.value.rezervasyon_tarihi.giris &&
        expo.form.value.rezervasyon_tarihi.cikis &&
        expo.form.value.toplam_gun > 0
      ) {
        return true;
      } else {
        return false;
      }
    });

    expo.handlerRezervasyonSorgula = () => {
      expo.loaded.value = true;

      const pax = expo.form.value.pax.yetiskin + expo.form.value.pax.cocuk;
      const villa_pax = parseInt(product.value.villa_ozellik.kisi);

      if (pax > villa_pax) {
        toast.error('Kişi sayısı villa kişi sayısından fazla olamaz.', {
          position: 'bottom-right',
        });
        expo.loaded.value = false;
      } else {
        axiosIns
          .post('/travel/rezervasyon-sorgula-villa-yat-post', {
            urun_k_no: product.value.k_no,
            rezervasyon_tarihi: expo.form.value.rezervasyon_tarihi,
          })
          .then((res) => {
            if (res.data.success) {
              let sabit_kur = expo.kurlar.value.find((x) => x.banka_kodu == res.data.kur_turu);

              let gun_fiyatlar = [];
              let baslik = {};
              let slug = {};

              expo.rezervasyon.value.sepet_urunler = [];

              expo.rezervasyon.value.k_no = registerNumber('REZ');
              expo.rezervasyon.value.lang = expo.defaultDil.value;
              expo.rezervasyon.value.satis_yontemi = res.data.urun.satis_yontemi;
              expo.rezervasyon.value.sabit_kur = res.data.kur_turu;
              expo.rezervasyon.value.sabit_kur_fiyat = sabit_kur.satis;

              Object.keys(product.value.icerik).map((el) => {
                baslik[el] = product.value.icerik[el].baslik;
                slug[el] = product.value.icerik[el].slug;
              });

              expo.form.value.urun_k_no = product.value.k_no;
              expo.form.value.urun_tipi = res.data.urun_tipi;
              expo.form.value.bilet_no = registerTicketNumber('TX');
              expo.form.value.urun_kodu = product.value.urun_kodu;
              expo.form.value.baslik = baslik;
              expo.form.value.slug = slug;
              expo.form.value.gorseller = product.value.gorseller.length && product.value.gorseller[0];
              expo.form.value.kategori = product.value.kategori[0].slug;
              expo.form.value.bugun_satis_fiyat = product.value.bugun_satis_fiyat;
              expo.form.value.kur_turu = product.value.kur_turu;
              expo.form.value.kdv_turu = product.value.kdv_turu;
              expo.form.value.kdv_oran = product.value.kdv_oran;
              expo.form.value.ekstra_hizmet_k_no = product.value.ekstra_hizmet_k_no;
              expo.form.value.odeme_tipi_kontrol = res.data.odeme_tipi;
              expo.form.value.odeme_tipi = res.data.odeme_tipi;

              expo.form.value.depozito_tahsil_durum = product.value.depozito_tahsil_durum;
              expo.form.value.depozito = product.value.depozito;
              expo.form.value.satis_yontemi = product.value.satis_yontemi;
              expo.form.value.on_odeme_oran = res.data.odeme_tipi == 'hepsi' ? 0 : res.data.odeme_tipi_oran;
              expo.form.value.toplam_gun = res.data.toplam_gun;

              expo.form.value.sabit_toplam.toplam = res.data.toplam_tutar;

              if (product.value.kampanya_k_no) {
                expo.form.value.kampanya = {
                  indirim_turu: product.value.kampanya.indirim_turu,
                  indirim_tutari: product.value.kampanya.indirim_tutari,
                };
              }

              if (expo.form.value.satis_yontemi == 'sorgula') {
                expo.form.value.odeme_yontemi = 'kapida_odeme';
              } else {
                expo.form.value.odeme_yontemi = res.data.odeme_yontemi;
              }

              res.data.fiyatlar.forEach((x) => {
                const day = gun_fiyatlar.findIndex((z) => z.fiyat == x);
                if (day != -1) {
                  gun_fiyatlar[day].gun = gun_fiyatlar[day].gun + 1;
                } else {
                  gun_fiyatlar.push({ fiyat: x, gun: 1 });
                }
              });

              expo.form.value.gun_fiyatlar = gun_fiyatlar;

              expo.loaded.value = false;
            } else {
              if (res.data.errorcode == 'min_konaklama') {
                toast.error(`Minimum Konakalama ${product.value.minimum_konaklama} gün olmalıdır`, {
                  position: 'bottom-right',
                });
              } else if (res.data.errorcode == 'fiyat_yok') {
                toast.error('Seçilen tarihlerde fiyat bulunmadı.', {
                  position: 'bottom-right',
                });
              }

              expo.loaded.value = false;
            }
          });
      }
    };

    expo.handlerSepeteEkle = (type) => {
      const urunVarmi = expo.rezervasyon.value.sepet_urunler.find(
        (x) => x.k_no == product.value.k_no && x.rezervasyon_tarihi == expo.form.value.rezervasyon_tarihi
      );

      if (expo.form.value.rezervasyon_tarihi.giris == null || expo.form.value.rezervasyon_tarihi.cikis == null) {
        toast.warning('Zorunlu alanları doldurunuz.', {
          position: 'top-center',
        });
      } else if (urunVarmi) {
        toast.warning('Bu ürün eklenmiştir.', {
          position: 'top-center',
        });
      } else {
        store.commit('SET_SEPET_SORSAT_URUN_PUSH', expo.form.value);

        toast.success('Ürün sepete Eklendi.', {
          position: 'top-center',
        });

        if (type) {
          router.push({ name: 'rezervasyon-sorgula' });
        }
      }
    };

    expo.handlerRezervasyonTemizle = () => {
      // store.commit('rezervasyon/SET_SEPET_DIREK_REZERVASYON_RESET');
      expo.form.value = {
        urun_k_no: null,
        bilet_no: null,
        urun_tipi: 'villa',
        urun_kodu: null,
        baslik: null,
        gorseller: null,
        rezervasyon_tarihi: {
          giris: null,
          cikis: null,
        },
        pax: {
          yetiskin: 1,
          cocuk: 0,
          bebek: 0,
        },
        seans_saati: null,
        bugun_satis_fiyat: null,
        kur_turu: null,
        kdv_turu: null,
        kdv_oran: 0,
        kampanya: {},
        ekstra_hizmet_sec: false,
        ekstra_hizmet_k_no: [],
        ekstra_hizmetler: [],
        depozito_tahsil_durum: false,
        depozito: null,
        gun_fiyatlar: [],
        odeme_turu: 'hepsi', // hepsi,on_odeme
        toplam_gun: 0,
        on_odeme_oran: 0,
        sabit_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: 0,
          ekstra_toplam: 0,
          kupon_indirim: 0,
          kdv_toplam: 0,
          genel_toplam: 0,
        },
        kur_toplam: {
          yetiskin: 0,
          cocuk: 0,
          bebek: 0,
          toplam: 0,
          ekstra_toplam: 0,
          kupon_indirim: 0,
          kdv_toplam: 0,
          genel_toplam: 0,
        },
      };
      expo.date.value = null;
    };

    onMounted(() => {
      product.value.satis_fiyat.forEach((el) => {
        let bas_tarih = moment(el.bas_tarih).format('DD.MM.YYYY');
        let bit_tarih = moment(el.bit_tarih).format('DD.MM.YYYY');
        expo.config.value.enable.push({ from: bas_tarih, to: bit_tarih });
      });

      // if (product.value.k_no != expo.rezervasyon.value.urun_k_no) store.commit('rezervasyon/SET_SEPET_DIREK_REZERVASYON_RESET');
    });

    watch(
      expo.form,
      (val) => {
        if (val.toplam_gun > 0) {
          const pax = val.pax.yetiskin + val.pax.cocuk;
          const villa_pax = parseInt(product.value.villa_ozellik.kisi);
          if (pax > villa_pax) {
            toast.error('Kişi sayısı villa kişi sayısından fazla olamaz.', {
              position: 'bottom-right',
            });
            expo.handlerRezervasyonTemizle();
          }
        }
      },
      { deep: true }
    );
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.flat-input {
  width: 100% !important;
  // height: 48px !important;
  background: #fff;
}
// .flatpickr-input[readonly] {
//   outline: none !important;
//   border-color: none;
//   border: none;
//   font-size: 18px;
//   display: block;
// }
</style>
